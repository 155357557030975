import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, TableCell, TableRow, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useSnackbar } from 'notistack'
import { Layout } from 'views/components/template/Layout'
import { ListWrapper } from 'views/components/template/ListWrapper'
import { SearchTextField } from 'views/components/molecules/SearchTextField'
import { LinkButton } from 'views/components/atoms/LinkButton'
import { Pagination } from 'views/components/organisms/Pagination'
import { ListBody } from 'views/components/template/ListBody'
import { TablePaginationWrapper } from 'views/components/molecules/TablePaginationWrapper'
import { TableList } from 'views/components/organisms/TableList'
import {
  GetAdminUserListQuery,
  GetAdminUserListQueryVariables,
  TableAdminUserFilterInput
} from 'API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { getAdminUserList } from 'graphql/queries'
import { CallGraphQL } from 'utils/api.utils'
import { SearchConditionContext } from 'contexts/SearchConditionContext'
import { AdminUser, TableHeadColumn, Validate } from 'common/Type'
import { Label } from 'views/components/atoms/Label'
import { Dropdown } from 'views/components/atoms/Dropdown'
import { SearchConditionChipWrapper } from 'views/components/molecules/SearchConditionChipWrapper'
import { CommonChip } from 'views/components/atoms/CommonChip'

const PAGE_LIMIT = 15

const headColumn: TableHeadColumn[] = [
  { id: 'status', label: '状態', minWidth: 150, width: 150, hasSort: false },
  { id: 'name', label: '氏名', hasSort: false },
  { id: 'username', label: 'メールアドレス', hasSort: false }
]

function CellWithStatus(status: number) {
  let color = 'gray'
  let label = '不明'
  switch (status) {
    case 1:
      color = 'blue'
      label = '利用中'
      break
    case 2:
      color = 'gray'
      label = '停止中'
      break
    case 3:
      color = 'gray'
      label = '削除'
      break
  }
  return <Label color={color} text={label} />
}

type UserSettingListSearchValidate = {
  name?: Validate
  status?: Validate
  email?: Validate
}

type UserSettingListSearchCondition = {
  name?: string
  status?: string
  username?: string
}

export function UserSettingList() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { searchConditionState, searchConditionDispatch } = useContext(
    SearchConditionContext
  )
  const [searchCondition, setSearchCondition] =
    useState<UserSettingListSearchCondition>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [validate, setValidate] = useState<UserSettingListSearchValidate>()
  const [count, setCount] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(
    searchConditionState &&
      searchConditionState.searchCondition &&
      searchConditionState.searchCondition.adminUser &&
      searchConditionState.searchCondition.adminUser.pageNumber &&
      new RegExp(/^\d+$/).test(
        searchConditionState.searchCondition.adminUser.pageNumber
      )
      ? searchConditionState.searchCondition.adminUser.pageNumber
      : 1
  )
  const [adminUserList, setAdminUserList] = useState<AdminUser[]>()
  const [name, setName] = useState<string>(
    searchConditionState?.searchCondition?.adminUser?.search?.name?.contains
      ? searchConditionState.searchCondition.adminUser.search.name.contains
      : ''
  )
  const [status, setStatus] = useState<string>(
    searchConditionState?.searchCondition?.adminUser?.search?.status?.eq
      ? searchConditionState.searchCondition.adminUser.search.status.eq
      : ''
  )
  const [username, setUsername] = useState<string>(
    searchConditionState?.searchCondition?.adminUser?.search?.username?.contains
      ? searchConditionState.searchCondition.adminUser.search.username.contains
      : ''
  )

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage + 1)
  }
  const handleCustomChangePage = (newPage: number) => {
    setPageNumber(newPage)
  }

  const handleRowClick = (id: string) => {
    history.push({
      pathname: `/setting/user/edit/${id}`
    })
  }

  useEffect(() => {
    fetchData()
  }, [pageNumber, searchCondition])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([fetchAdminUserList()])
    setIsLoading(false)
  }

  function search() {
    crateSearchParam()
    setPageNumber(1)
  }

  function crateSearchParam() {
    const param: UserSettingListSearchCondition = {}
    if (name) {
      param.name = name
    }
    if (status) {
      param.status = status
    }
    if (username) {
      param.username = username
    }
    setSearchCondition(param)
  }

  function validateSearch() {
    const valid: UserSettingListSearchValidate = {}
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  async function fetchAdminUserList() {
    const tableAdminUserFilterInput = createTableAdminUserFilterInput()
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0

    const params: GetAdminUserListQueryVariables = {
      filter: tableAdminUserFilterInput,
      offset: offset,
      limit: PAGE_LIMIT
    }
    const getAdminUserListResult = await callGetAdminUserList(params)
    if (getAdminUserListResult?.data?.getAdminUserList) {
      const getAdminUserListResultData =
        getAdminUserListResult.data.getAdminUserList
      setAdminUserList(getAdminUserListResultData.data as AdminUser[])
      if (getAdminUserListResultData.paging) {
        setCount(getAdminUserListResultData.paging.count)
      }
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  function createTableAdminUserFilterInput(): TableAdminUserFilterInput {
    const param: TableAdminUserFilterInput = {}

    if (searchCondition?.name) {
      param.name = { contains: searchCondition.name }
    }

    if (searchCondition?.status) {
      param.status = { eq: Number(searchCondition.status) }
    }

    if (searchCondition?.username) {
      param.username = { contains: searchCondition.username }
    }

    searchConditionDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      searchCondition: { adminUser: { search: param, pageNumber: pageNumber } }
    })

    return param
  }

  async function callGetAdminUserList(
    params: GetAdminUserListQueryVariables
  ): Promise<GraphQLResult<GetAdminUserListQuery>> {
    return await CallGraphQL(getAdminUserList, params, false)
  }

  function searchChips() {
    if (
      !searchCondition?.name &&
      !searchCondition?.status &&
      !searchCondition?.username
    ) {
      return undefined
    }

    let statusLabel
    if (searchCondition?.status) {
      switch (searchCondition?.status) {
        case '1':
          statusLabel = '利用中'
          break
        case '2':
          statusLabel = '停止中'
          break
        case '3':
          statusLabel = '削除'
          break
      }
    }
    return (
      <>
        {searchCondition?.name && (
          <CommonChip
            label={`氏名: ${searchCondition.name}`}
            onDelete={(e) => {
              setName('')
              delete searchCondition['name']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
        {searchCondition?.status && statusLabel && (
          <CommonChip
            label={`状態: ${statusLabel}`}
            onDelete={(e) => {
              setStatus('')
              delete searchCondition['status']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
        {searchCondition?.username && (
          <CommonChip
            label={`メールアドレス: ${searchCondition.username}`}
            onDelete={(e) => {
              setUsername('')
              delete searchCondition['username']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
      </>
    )
  }

  return (
    <Layout
      title={'利用者管理'}
      headerComponent={
        <SearchTextField
          searchWord={name}
          setSearchWord={setName}
          searchPlaceholder={'氏名を検索'}
          search={search}
          validateSearch={validateSearch}
          searchDetailed={
            <>
              <Grid item xs={12}>
                <TextField
                  label={'氏名'}
                  id="name"
                  fullWidth
                  autoComplete="name"
                  variant="filled"
                  value={name}
                  defaultValue={name}
                  onChange={(event) => {
                    setName(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name={'status'}
                  label={'状態'}
                  defaultValue={status}
                  value={status}
                  setValue={setStatus}
                  items={[
                    { label: '', value: '0' },
                    { label: '利用中', value: '1' },
                    { label: '停止中', value: '2' }
                  ]}
                  hasError={validate?.status?.isError}
                  helperText={validate?.status?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={'メールアドレス'}
                  id="email"
                  fullWidth
                  autoComplete="email"
                  variant="filled"
                  value={username}
                  defaultValue={username}
                  onChange={(event) => {
                    setUsername(event.target.value)
                  }}
                />
              </Grid>
            </>
          }
        />
      }
    >
      <ListWrapper>
        <SearchConditionChipWrapper chips={searchChips()} />
        <Grid container alignItems={'center'} spacing={3} xs={12}>
          <Grid item xs={3}>
            <LinkButton
              color={'primary'}
              name={'新規追加'}
              to={'/setting/user/edit'}
              startIcon={<AddIcon />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <TablePaginationWrapper
              isLoading={isLoading}
              count={count}
              rowsPerPage={PAGE_LIMIT}
              page={pageNumber - 1}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
        <ListBody isLoading={isLoading}>
          <TableList
            tableHeadColumnList={headColumn}
            handlePageNumber={handleCustomChangePage}
          >
            {adminUserList &&
              adminUserList.length > 0 &&
              adminUserList.map((row) => {
                return (
                  <TableRow
                    onClick={() => handleRowClick(row.id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell>
                      {row.status && CellWithStatus(row.status)}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.username}</TableCell>
                  </TableRow>
                )
              })}
          </TableList>
        </ListBody>
        <Pagination
          isLoading={isLoading}
          handlePageNumber={handleCustomChangePage}
          pageNumber={pageNumber}
          count={count}
          rowsPerPage={PAGE_LIMIT}
        />
      </ListWrapper>
    </Layout>
  )
}
