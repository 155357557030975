import React from 'react'
import { TablePagination } from '@material-ui/core'
import { Loading } from 'views/components/atoms/Loaging'

type Props = {
  isLoading: boolean
  count: number
  rowsPerPage: number
  page: number
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void
}

export const TablePaginationWrapper = React.memo(
  ({ isLoading, count, rowsPerPage, page, onChangePage }: Props) => {
    return (
      <>
        {isLoading ? (
          <Loading isLoading={isLoading} marginTop={3} marginBottom={4} />
        ) : (
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
          />
        )}
      </>
    )
  }
)
