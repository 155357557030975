import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Auth } from './Auth'
import { Signin } from 'views/components/pages/Signin/Index'
import { Empty } from 'views/components/pages/Empty/Index'
import { CompanyNoticeList } from 'views/components/pages/Notice/Company/List/Index'
import { CompanyNoticeEdit } from 'views/components/pages/Notice/Company/Edit/Index'
import { CustomerNoticeList } from 'views/components/pages/Notice/Customer/List/Index'
import { CustomerNoticeEdit } from 'views/components/pages/Notice/Customer/Edit/Index'
import { CompanyManagementList } from 'views/components/pages/Management/Company/List/Index'
import { CompanyManagementDetail } from 'views/components/pages/Management/Company/Detail/Index'
import { CompanyManagementProxySignin } from 'views/components/pages/Management/Company/Proxy/Signin/Index'
import { DataManagementList } from 'views/components/pages/Management/Data/Index'
import { UserSettingList } from 'views/components/pages/Setting/User/List/Index'
import { UserSettingEdit } from 'views/components/pages/Setting/User/Edit/Index'

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/authorize/signin" component={Signin} />
        <Auth>
          <Switch>
            <Route
              exact
              path="/notice/company/list"
              component={CompanyNoticeList}
            />
            <Route
              exact
              path="/notice/company/edit"
              component={CompanyNoticeEdit}
            />
            <Route
              exact
              path="/notice/company/edit/:id"
              component={CompanyNoticeEdit}
            />
            <Route
              exact
              path="/notice/customer/list"
              component={CustomerNoticeList}
            />
            <Route
              exact
              path="/notice/customer/edit"
              component={CustomerNoticeEdit}
            />
            <Route
              exact
              path="/notice/customer/edit/:id"
              component={CustomerNoticeEdit}
            />
            <Route
              exact
              path="/management/company/list"
              component={CompanyManagementList}
            />
            <Route
              exact
              path="/management/company/:id"
              component={CompanyManagementDetail}
            />
            <Route
              exact
              path="/management/company/:id/proxy/signin"
              component={CompanyManagementProxySignin}
            />
            <Route
              exact
              path="/management/data"
              component={DataManagementList}
            />
            <Route
              exact
              path="/setting/user/list"
              component={UserSettingList}
            />
            <Route
              exact
              path="/setting/user/edit"
              component={UserSettingEdit}
            />
            <Route
              exact
              path="/setting/user/edit/:id"
              component={UserSettingEdit}
            />
            <Route component={Empty} />
          </Switch>
        </Auth>
      </Switch>
    </BrowserRouter>
  )
}
