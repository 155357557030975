import React, { ChangeEvent, Dispatch, SetStateAction, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { SvgIconProps } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import { FileObject } from '../../../common/Type'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.blue.main,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

type Props = {
  text: string
  accept: string
  files: FileObject[]
  setFiles: Dispatch<SetStateAction<FileObject[]>>
  onClick?: (event: ChangeEvent<HTMLInputElement>) => void
  startIcon?: React.HTMLProps<SvgIconProps>
  endIcon?: React.HTMLProps<SvgIconProps>
  hasError?: boolean
  helperText?: string
}

export const FileAddButton = React.memo(
  ({
    text,
    accept,
    files,
    setFiles,
    onClick,
    startIcon,
    endIcon,
    hasError,
    helperText
  }: Props) => {
    const classes = useStyles()

    const inputRef = useRef<HTMLInputElement>(null)

    const fileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target?.files) {
        return
      }
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const obj: FileObject = {
          mimeType: file.type,
          filename: file.name,
          body: reader.result,
          file: file
        }
        setFiles([...files, obj])
      }
      reader.readAsText(file)
      if (onClick) {
        onClick(event)
      }
      event.target.value = ''
    }

    const handleClick = () => {
      inputRef.current?.click()
    }

    return (
      <>
        <Button
          fullWidth
          size="large"
          variant="outlined"
          component="span"
          startIcon={startIcon}
          endIcon={endIcon}
          className={classes.button}
          onClick={handleClick}
        >
          {text}
        </Button>
        {helperText && (
          <FormHelperText error={hasError}>{helperText}</FormHelperText>
        )}
        <input
          id="contained-button-file"
          type="file"
          hidden
          ref={inputRef}
          accept={accept}
          onChange={fileInputChange}
        />
      </>
    )
  }
)
