import React, { useReducer, createContext, ReactNode } from 'react'
import {
  State,
  Action,
  searchConditionReducer
} from 'reducers/SearchConditionReducer'

type ContextType = {
  searchConditionState: State
  searchConditionDispatch: React.Dispatch<Action>
}

export const SearchConditionContext = createContext({} as ContextType)

type Props = {
  children: ReactNode
}

const initialState = { searchCondition: {} }

export function SearchConditionProvider({ children }: Props) {
  const [searchConditionState, searchConditionDispatch] = useReducer(
    searchConditionReducer,
    initialState
  )

  return (
    <SearchConditionContext.Provider
      value={{ searchConditionState, searchConditionDispatch }}
    >
      {children}
    </SearchConditionContext.Provider>
  )
}
