import React, { ReactNode, useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { LastPathContext } from 'contexts/LastPathContext'

const AWS = require('aws-sdk')
AWS.config.region = process.env.REACT_APP_AWS_REGION

type Props = {
  children: ReactNode
}

export const Auth = ({ children }: Props) => {
  const history = useHistory()
  const { lastPathDispatch } = useContext(LastPathContext)

  if (AWS.config.credentials && !AWS.config.credentials.needsRefresh()) {
    return <>{children}</>
  }
  lastPathDispatch({
    type: 'SET', // reducerで指定したtypeを使う
    lastPath: history.location.pathname
  })

  return (
    <>
      <Redirect to={'/authorize/signin'} />
    </>
  )
}
