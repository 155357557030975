import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  wrapBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  wrapContent: {
    flex: 1,
    display: 'flex',
    margin: '32px'
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
})

type Props = {
  children: ReactNode
}

export function ListWrapper({ children }: Props) {
  const classes = useStyles()
  return (
    <div className={classes.wrapBody}>
      <div className={classes.wrapContent}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  )
}
