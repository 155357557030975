import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, TableCell, TableRow, TextField } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { Layout } from 'views/components/template/Layout'
import { ListWrapper } from 'views/components/template/ListWrapper'
import { SearchTextField } from 'views/components/molecules/SearchTextField'
import { Pagination } from 'views/components/organisms/Pagination'
import { ListBody } from 'views/components/template/ListBody'
import { TablePaginationWrapper } from 'views/components/molecules/TablePaginationWrapper'
import { TableList } from 'views/components/organisms/TableList'
import {
  GetCompanyAndDashboardListQuery,
  GetCompanyAndDashboardListQueryVariables,
  GetPrefectureListQuery,
  TableCompanyFilterInput
} from 'API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { getCompanyAndDashboardList, getPrefectureList } from 'graphql/queries'
import { CallGraphQL } from 'utils/api.utils'
import { SearchConditionContext } from 'contexts/SearchConditionContext'
import {
  CompanyAndDashboard,
  Prefecture,
  SelectItem,
  TableHeadColumn,
  Validate
} from 'common/Type'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Dropdown } from 'views/components/atoms/Dropdown'
import { CustomDatePicker } from 'views/components/atoms/CustomDatePicker'
import { SearchConditionChipWrapper } from 'views/components/molecules/SearchConditionChipWrapper'
import { CommonChip } from 'views/components/atoms/CommonChip'

const PAGE_LIMIT = 15

const headColumn: TableHeadColumn[] = [
  { id: 'name', label: '社名', hasSort: false },
  { id: 'name', label: '都道府県', minWidth: 150, width: 150, hasSort: false },
  {
    id: 'totalAvailableRooms',
    label: '対象戸数',
    minWidth: 150,
    width: 150,
    hasSort: false
  },
  {
    id: 'totalContracts',
    label: '入居者数',
    minWidth: 75,
    width: 150,
    hasSort: false
  },
  {
    id: 'totalAppUsers',
    label: 'アプリ利用者',
    minWidth: 150,
    width: 150,
    hasSort: false
  },
  {
    id: 'managementSignin',
    label: '代理ログイン',
    minWidth: 150,
    width: 150,
    hasSort: false
  }
]

const useStyles = makeStyles({
  proxyLoginLink: {
    textDecoration: 'none'
  }
})

function CellWithPrefecture(id: number | null, map?: Map<number, Prefecture>) {
  if (id && map && map.size > 0) {
    const prefecture = map.get(id)
    if (prefecture) {
      return <>{prefecture.name}</>
    }
  }
  return <></>
}

type CompanyManagementSearchValidate = {
  name?: Validate
  prefCd?: Validate
  createdAtTo?: Validate
  createdAtFrom?: Validate
}

type CompanyManagementSearchCondition = {
  name?: string
  prefCd?: string
  createdAtTo?: string
  createdAtFrom?: string
}

export function CompanyManagementList() {
  const history = useHistory()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { searchConditionState, searchConditionDispatch } = useContext(
    SearchConditionContext
  )
  const [searchCondition, setSearchCondition] =
    useState<CompanyManagementSearchCondition>({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [validate, setValidate] = useState<CompanyManagementSearchValidate>()
  const [count, setCount] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(
    searchConditionState &&
      searchConditionState.searchCondition &&
      searchConditionState.searchCondition.companyManagement &&
      searchConditionState.searchCondition.companyManagement.pageNumber &&
      new RegExp(/^\d+$/).test(
        searchConditionState.searchCondition.companyManagement.pageNumber
      )
      ? searchConditionState.searchCondition.companyManagement.pageNumber
      : 1
  )
  const [companyAndDashboardList, setCompanyAndDashboardListList] =
    useState<CompanyAndDashboard[]>()
  const [prefectureMap, setPrefectureMap] = useState<Map<number, Prefecture>>()
  const [prefectureSelectItems, setPrefectureSelectItems] = useState<
    SelectItem[]
  >([])
  const [name, setName] = useState<string>(
    searchConditionState &&
      searchConditionState.searchCondition &&
      searchConditionState.searchCondition.companyManagement &&
      searchConditionState.searchCondition.companyManagement.search &&
      searchConditionState.searchCondition.companyManagement.search.name &&
      searchConditionState.searchCondition.companyManagement.search.name
        .contains
      ? searchConditionState.searchCondition.companyManagement.search.name
          .contains
      : ''
  )
  const [prefCd, setPrefCd] = useState<string>(
    searchConditionState?.searchCondition?.companyManagement?.search?.created_by
      ?.eq
      ? searchConditionState?.searchCondition?.companyManagement?.search
          ?.created_by?.eq
      : ''
  )
  const [createdAtFrom, setCreatedAtFrom] = useState<string | null>(
    searchConditionState?.searchCondition?.companyManagement?.search?.created_at
      ?.between?.length == 2
      ? searchConditionState?.searchCondition?.companyManagement?.search
          ?.created_at?.between[0]
      : null
  )
  const [createdAtTo, setCreatedAtTo] = useState<string | null>(
    searchConditionState?.searchCondition?.companyManagement?.search?.created_at
      ?.between?.length == 2
      ? searchConditionState?.searchCondition?.companyManagement?.search
          ?.created_at?.between[1]
      : null
  )

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage + 1)
  }
  const handleCustomChangePage = (newPage: number) => {
    setPageNumber(newPage)
  }

  const handleRowClick = (id: string) => {
    history.push({
      pathname: `/management/company/${id}`
    })
  }

  const handleProxyLoginClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    window.open(`/management/company/${id}/proxy/signin`)
    event.stopPropagation()
  }

  useEffect(() => {
    fetchData()
  }, [pageNumber, searchCondition])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([
      fetchCompanyAndDashboardList(),
      !prefectureMap ? fetchPrefectureMap() : null
    ])
    setIsLoading(false)
  }

  function search() {
    crateSearchParam()
    setPageNumber(1)
  }

  function crateSearchParam() {
    const param: CompanyManagementSearchCondition = {}
    if (name) {
      param.name = name
    }
    if (prefCd) {
      param.prefCd = prefCd
    }
    if (createdAtFrom) {
      param.createdAtFrom = createdAtFrom
    }
    if (createdAtTo) {
      param.createdAtTo = createdAtTo
    }

    setSearchCondition(param)
  }

  function validateSearch() {
    const valid: CompanyManagementSearchValidate = {}
    if (!createdAtTo && createdAtFrom) {
      valid.createdAtTo = {
        isError: true,
        message: '登録日(From)が指定されてる時は登録日(To)は必須です'
      }
    }
    if (createdAtTo && !createdAtFrom) {
      valid.createdAtFrom = {
        isError: true,
        message: '登録日(To)が指定されてる時は登録日(From)は必須です'
      }
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  async function fetchPrefectureMap() {
    const getPrefectureListResult = await callGetPrefectureList()
    if (
      getPrefectureListResult.data &&
      getPrefectureListResult.data.getPrefectureList
    ) {
      const getPrefectureListResultData =
        getPrefectureListResult.data.getPrefectureList
      if (!getPrefectureListResultData.data) {
        return
      }
      const map = new Map<number, Prefecture>()
      const prefectureSelectItemList: SelectItem[] = [{ label: '', value: '' }]
      for (let i of getPrefectureListResultData.data) {
        if (i) {
          map.set(i.id, i as Prefecture)
          prefectureSelectItemList.push({ label: i.name, value: String(i.id) })
        }
      }
      setPrefectureMap(map)
      setPrefectureSelectItems(prefectureSelectItemList)
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callGetPrefectureList(): Promise<
    GraphQLResult<GetPrefectureListQuery>
  > {
    return await CallGraphQL(getPrefectureList, undefined, false)
  }

  async function fetchCompanyAndDashboardList() {
    const tableCustomerNoticeFilterInput = createTableCompanyFilterInput()
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0

    const params: GetCompanyAndDashboardListQueryVariables = {
      filter: tableCustomerNoticeFilterInput,
      offset: offset,
      limit: PAGE_LIMIT
    }
    const getCompanyAndDashboardListResult =
      await callGetCompanyAndDashboardList(params)
    if (
      getCompanyAndDashboardListResult.data &&
      getCompanyAndDashboardListResult.data.getCompanyAndDashboardList
    ) {
      const getCompanyAndDashboardListResultData =
        getCompanyAndDashboardListResult.data.getCompanyAndDashboardList
      setCompanyAndDashboardListList(
        getCompanyAndDashboardListResultData.data as CompanyAndDashboard[]
      )
      if (getCompanyAndDashboardListResultData.paging) {
        setCount(getCompanyAndDashboardListResultData.paging.count)
      }
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  function createTableCompanyFilterInput(): TableCompanyFilterInput {
    const param: TableCompanyFilterInput = {}

    if (searchCondition?.name) {
      param.name = { contains: searchCondition.name }
    }
    if (searchCondition?.prefCd) {
      param.prefecture_id = { eq: Number(searchCondition.prefCd) }
    }
    if (searchCondition?.createdAtTo && searchCondition?.createdAtFrom) {
      param.created_at = {
        between: [searchCondition.createdAtFrom, searchCondition.createdAtTo]
      }
    }

    searchConditionDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      searchCondition: {
        companyManagement: { search: param, pageNumber: pageNumber }
      }
    })

    return param
  }

  async function callGetCompanyAndDashboardList(
    params: GetCompanyAndDashboardListQueryVariables
  ): Promise<GraphQLResult<GetCompanyAndDashboardListQuery>> {
    return await CallGraphQL(getCompanyAndDashboardList, params, false)
  }

  function searchChips() {
    if (
      !searchCondition?.name &&
      !searchCondition?.prefCd &&
      !searchCondition?.createdAtFrom &&
      !searchCondition?.createdAtTo
    ) {
      return undefined
    }

    let prefLabel = ''
    if (
      searchCondition?.prefCd &&
      prefectureMap?.has(Number(searchCondition?.prefCd))
    ) {
      prefLabel = prefectureMap.get(Number(searchCondition.prefCd))!.name
    }

    return (
      <>
        {searchCondition?.name && (
          <CommonChip
            label={`管理会社名: ${searchCondition.name}`}
            onDelete={(e) => {
              setName('')
              delete searchCondition['name']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
        {searchCondition?.prefCd && prefLabel && (
          <CommonChip
            label={`都道府県: ${prefLabel}`}
            onDelete={(e) => {
              setPrefCd('')
              delete searchCondition['prefCd']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
        {searchCondition?.createdAtFrom && (
          <CommonChip
            label={`登録日(From): ${searchCondition.createdAtFrom}`}
            onDelete={(e) => {
              setCreatedAtFrom(null)
              setCreatedAtTo(null)
              delete searchCondition['createdAtFrom']
              delete searchCondition['createdAtTo']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
        {searchCondition?.createdAtTo && (
          <CommonChip
            label={`登録日(To): ${searchCondition.createdAtTo}`}
            onDelete={(e) => {
              setCreatedAtFrom(null)
              setCreatedAtTo(null)
              delete searchCondition['createdAtFrom']
              delete searchCondition['createdAtTo']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
      </>
    )
  }

  return (
    <Layout
      title={'管理会社リスト'}
      headerComponent={
        <SearchTextField
          searchWord={name}
          setSearchWord={setName}
          searchPlaceholder={'管理会社名を検索'}
          search={search}
          validateSearch={validateSearch}
          searchDetailed={
            <>
              <Grid item xs={12}>
                <TextField
                  label={'管理会社名'}
                  id="name"
                  fullWidth
                  autoComplete="name"
                  variant="filled"
                  value={name}
                  defaultValue={name}
                  onChange={(event) => {
                    setName(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name={'prefCd'}
                  label={'都道府県'}
                  defaultValue={prefCd}
                  value={prefCd}
                  setValue={setPrefCd}
                  items={prefectureSelectItems}
                  hasError={validate?.prefCd?.isError}
                  helperText={validate?.prefCd?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  label="登録日(From)"
                  format="yyyy-MM-dd"
                  value={createdAtFrom}
                  setValue={setCreatedAtFrom}
                  isError={validate?.createdAtFrom?.isError}
                  helperText={validate?.createdAtFrom?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  label="登録日(To)"
                  format="yyyy-MM-dd"
                  value={createdAtTo}
                  setValue={setCreatedAtTo}
                  isError={validate?.createdAtTo?.isError}
                  helperText={validate?.createdAtTo?.message}
                />
              </Grid>
            </>
          }
        />
      }
    >
      <ListWrapper>
        <SearchConditionChipWrapper chips={searchChips()} />
        <Grid container spacing={3} xs={12}>
          <Grid item xs={3} />
          <Grid item xs={6} />
          <Grid item xs={3}>
            <TablePaginationWrapper
              isLoading={isLoading}
              count={count}
              rowsPerPage={PAGE_LIMIT}
              page={pageNumber - 1}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
        <ListBody isLoading={isLoading}>
          <TableList
            tableHeadColumnList={headColumn}
            handlePageNumber={handleCustomChangePage}
          >
            {companyAndDashboardList &&
              companyAndDashboardList.length > 0 &&
              companyAndDashboardList.map((row) => {
                return (
                  <TableRow
                    onClick={() => {
                      row.company && handleRowClick(row.company.id)
                    }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell>{row.company && row.company.name}</TableCell>
                    <TableCell>
                      {row.company &&
                        CellWithPrefecture(
                          row.company.prefecture_id,
                          prefectureMap
                        )}
                    </TableCell>
                    <TableCell>
                      {row.dashboard && row.dashboard.totalAvailableRooms}
                    </TableCell>
                    <TableCell>
                      {row.dashboard && row.dashboard.totalContracts}
                    </TableCell>
                    <TableCell>
                      {row.dashboard && row.dashboard.totalAppUsers}
                    </TableCell>
                    <TableCell>
                      {row.company && (
                        <Button
                          onClick={(e) => {
                            row.company &&
                              handleProxyLoginClick(e, row.company.id)
                          }}
                          variant="contained"
                          color="primary"
                        >
                          ログイン
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableList>
        </ListBody>
        <Pagination
          isLoading={isLoading}
          handlePageNumber={handleCustomChangePage}
          pageNumber={pageNumber}
          count={count}
          rowsPerPage={PAGE_LIMIT}
        />
      </ListWrapper>
    </Layout>
  )
}
