import React, { ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

type StyleProps = {
  size?: string
  disabled?: boolean
  active?: boolean
}

const circleStyle = makeStyles<Theme, StyleProps>((theme) => {
  const setFontColor = (active?: boolean) =>
    active ? theme.palette.white.main : theme.palette.black.main
  const setBgColor = (active?: boolean) =>
    active ? theme.palette.blue.main : theme.palette.white.main
  const setSize = (size?: string) => {
    if (size === 'large') return 77
    if (size === 'medium') return 40
    if (size === 'small') return 20
    return 40
  }
  const setHoverBgColor = (disabled?: boolean) =>
    disabled ? 'inherited' : '#d2d3ff'
  const setHoverCursor = (disabled?: boolean) =>
    disabled ? 'inherited' : 'pointer'
  return {
    circle: {
      alignItems: 'center',
      color: (props) => setFontColor(props.active),
      backgroundColor: (props) => setBgColor(props.active),
      fontWeight: 'bold',
      fontSize: (props) => `${setSize(props.size) / 2}px`,
      lineHeight: (props) => `${setSize(props.size)}px`,
      height: (props) => setSize(props.size),
      width: (props) => setSize(props.size),
      borderRadius: '50%',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      overflow: 'hidden',
      position: 'relative',
      '&:hover': {
        backgroundColor: (props) => setHoverBgColor(props.disabled),
        cursor: (props) => setHoverCursor(props.disabled)
      },
      '& img': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '-webkit-transform': 'translate(-50%, -50%)',
        '-ms-transform': ' translate(-50%, -50%)',
        width: '100%',
        height: 'auto%'
      }
    }
  }
})

type Props = {
  size?: string
  disabled?: boolean
  active?: boolean
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  children: ReactNode
}

export const Circle = React.memo((props: Props) => {
  const classes = circleStyle(props)
  return (
    <div className={classes.circle} {...props} onClick={props.onClick}>
      {props.children}
    </div>
  )
})
