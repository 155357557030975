import React, { ReactNode } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { KeyStringValue } from '../../../common/Type'

type StyleProps = {
  color?: string
}

const labelStyles = makeStyles<Theme, StyleProps>((theme) => {
  const colorData: KeyStringValue = {
    white: {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.black.main
    },
    black: {
      backgroundColor: theme.palette.black.main,
      color: theme.palette.white.main
    },
    gray: {
      backgroundColor: theme.palette.darkGray.main,
      color: theme.palette.white.main
    },
    blue: {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.white.main
    },
    red: {
      backgroundColor: theme.palette.red.main,
      color: theme.palette.white.main
    }
  }
  return {
    label: {
      display: 'inline-block',
      padding: '0.3em 0.5em',
      lineHeight: '19px',
      fontWeight: 'bold',
      fontSize: '13px',
      textDecoration: 'none',
      minWidth: '55px',
      textAlign: 'center',
      color: (props) =>
        props.color && colorData[props.color]
          ? colorData[props.color].color
          : theme.palette.white.main,
      backgroundColor: (props) =>
        props.color && colorData[props.color]
          ? colorData[props.color].backgroundColor
          : theme.palette.gray.main,
      borderRadius: '4px'
    }
  }
})

type Props = {
  color?: string
  text?: string
  children?: ReactNode
}

export const Label = React.memo(({ color, text, children }: Props) => {
  const classes = labelStyles({ color })

  return <span className={classes.label}>{text ? text : children}</span>
})
