import React from 'react'
import { SnackbarProvider } from 'notistack'
import { LastPathProvider } from 'contexts/LastPathContext'
import { SearchConditionProvider } from 'contexts/SearchConditionContext'
import { AdminUserProvider } from 'contexts/AdminUserContext'
import { LoadingProvider } from 'contexts/LoadingContext'
import { BackDropLoading } from 'views/components/molecules/BackDropLoading'
import { Router } from 'common/Router'

function App() {
  return (
    <>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={3000}
      >
        <LastPathProvider>
          <SearchConditionProvider>
            <AdminUserProvider>
              <LoadingProvider>
                <BackDropLoading />
                <Router />
              </LoadingProvider>
            </AdminUserProvider>
          </SearchConditionProvider>
        </LastPathProvider>
      </SnackbarProvider>
    </>
  )
}

export default App
