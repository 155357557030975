import React from 'react'

export type State = {
  searchCondition: any
}

export type Action = {
  type: 'SET'
  searchCondition: any
}

export function searchConditionReducer(state: State, action: Action) {
  if (action.type === 'SET') {
    return { ...state, searchCondition: action.searchCondition }
  }
  throw new Error()
}
