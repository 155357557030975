import API, { graphqlOperation } from '@aws-amplify/api'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { Cache } from 'aws-amplify'

export const CallGraphQL = async (
  method: any,
  params?: {},
  enabledCache?: boolean
) => {
  try {
    const response: GraphQLResult | object = await API.graphql(
      graphqlOperation(method, params)
    )
    return response
  } catch (error) {
    console.log('error', error)
    return { error }
  }
}
