import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, TableCell, TableRow, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useSnackbar } from 'notistack'
import { Layout } from 'views/components/template/Layout'
import { ListWrapper } from 'views/components/template/ListWrapper'
import { SearchTextField } from 'views/components/molecules/SearchTextField'
import { LinkButton } from 'views/components/atoms/LinkButton'
import { Pagination } from 'views/components/organisms/Pagination'
import { ListBody } from 'views/components/template/ListBody'
import { TablePaginationWrapper } from 'views/components/molecules/TablePaginationWrapper'
import { TableList } from 'views/components/organisms/TableList'
import { DateLabel } from 'views/components/atoms/DateLabel'
import {
  GetAdminUserListQuery,
  GetAdminUserListQueryVariables,
  GetCompanyNoticeListQuery,
  GetCompanyNoticeListQueryVariables,
  TableCompanyNoticeFilterInput
} from 'API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { getAdminUserList, getCompanyNoticeList } from 'graphql/queries'
import { CallGraphQL } from 'utils/api.utils'
import { SearchConditionContext } from 'contexts/SearchConditionContext'
import {
  CompanyNoticeAndCreatedUserAndUpdatedUserDeletedUser,
  SelectItem,
  TableHeadColumn,
  Validate
} from 'common/Type'
import { Dropdown } from 'views/components/atoms/Dropdown'
import { CustomDatePicker } from 'views/components/atoms/CustomDatePicker'
import { SearchConditionChipWrapper } from 'views/components/molecules/SearchConditionChipWrapper'
import { CommonChip } from 'views/components/atoms/CommonChip'

const PAGE_LIMIT = 15

const headColumn: TableHeadColumn[] = [
  { id: 'body', label: '内容', hasSort: false },
  {
    id: 'created_at',
    label: '登録日時',
    minWidth: 150,
    width: 150,
    hasSort: false
  },
  {
    id: 'created_by',
    label: '登録者',
    minWidth: 150,
    width: 150,
    hasSort: false
  }
]

function CellWithBody(body: string) {
  if (body.length < 80) {
    return <>{body}</>
  }
  const slicedBody = body.slice(0, 80)
  return <>{slicedBody}</>
}

type CompanyNoticeSearchValidate = {
  body?: Validate
  createdAtTo?: Validate
  createdAtFrom?: Validate
  createdBy?: Validate
}

type CompanyNoticeSearchCondition = {
  body?: string
  createdAtFrom?: string
  createdAtTo?: string
  createdBy?: string
}

export function CompanyNoticeList() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { searchConditionState, searchConditionDispatch } = useContext(
    SearchConditionContext
  )
  const [searchCondition, setSearchCondition] =
    useState<CompanyNoticeSearchCondition>({})

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [validate, setValidate] = useState<CompanyNoticeSearchValidate>()
  const [count, setCount] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(
    searchConditionState &&
      searchConditionState.searchCondition &&
      searchConditionState.searchCondition.companyNotice &&
      searchConditionState.searchCondition.companyNotice.pageNumber &&
      new RegExp(/^\d+$/).test(
        searchConditionState.searchCondition.companyNotice.pageNumber
      )
      ? searchConditionState.searchCondition.companyNotice.pageNumber
      : 1
  )
  const [companyNoticeAndAdminUserList, setCompanyNoticeAndAdminUserList] =
    useState<CompanyNoticeAndCreatedUserAndUpdatedUserDeletedUser[]>()
  const [adminUserSelectItems, setAdminUserSelectItems] = useState<
    SelectItem[]
  >([])

  const [body, setBody] = useState<string>(
    searchConditionState?.searchCondition?.companyNotice?.search?.body?.contains
      ? searchConditionState.searchCondition.companyNotice.search.body.contains
      : ''
  )
  const [createdAtFrom, setCreatedAtFrom] = useState<string | null>(
    searchConditionState?.searchCondition?.companyNotice?.search?.created_at
      ?.between?.length == 2
      ? searchConditionState?.searchCondition?.companyNotice?.search?.created_at
          ?.between[0]
      : null
  )
  const [createdAtTo, setCreatedAtTo] = useState<string | null>(
    searchConditionState?.searchCondition?.companyNotice?.search?.created_at
      ?.between?.length == 2
      ? searchConditionState?.searchCondition?.companyNotice?.search?.created_at
          ?.between[1]
      : null
  )

  const [createdBy, setCreatedBy] = useState<string>(
    searchConditionState?.searchCondition?.companyNotice?.search?.created_by?.eq
      ? searchConditionState?.searchCondition?.companyNotice?.search?.created_by
          ?.eq
      : ''
  )
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage + 1)
  }
  const handleCustomChangePage = (newPage: number) => {
    setPageNumber(newPage)
  }
  const handleRowClick = (id: string) => {
    history.push({
      pathname: `/notice/company/edit/${id}`
    })
  }

  useEffect(() => {
    fetchData()
  }, [pageNumber, searchCondition])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([
      fetchCompanyNoticeList(),
      adminUserSelectItems.length > 0 ? null : fetchAdminUserList()
    ])
    setIsLoading(false)
  }

  function search() {
    crateSearchParam()
    setPageNumber(1)
  }

  function crateSearchParam() {
    const param: CompanyNoticeSearchCondition = {}
    if (body) {
      param.body = body
    }
    if (createdAtFrom) {
      param.createdAtFrom = createdAtFrom
    }
    if (createdAtTo) {
      param.createdAtTo = createdAtTo
    }
    if (createdBy) {
      param.createdBy = createdBy
    }
    setSearchCondition(param)
  }

  function validateSearch(): boolean {
    const valid: CompanyNoticeSearchValidate = {}
    if (!createdAtTo && createdAtFrom) {
      valid.createdAtTo = {
        isError: true,
        message: '登録日(From)が指定されてる時は登録日(To)は必須です'
      }
    }
    if (createdAtTo && !createdAtFrom) {
      valid.createdAtFrom = {
        isError: true,
        message: '登録日(To)が指定されてる時は登録日(From)は必須です'
      }
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  async function fetchCompanyNoticeList() {
    const tableCompanyNoticeFilterInput = createTableCompanyNoticeFilterInput()
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0

    const params: GetCompanyNoticeListQueryVariables = {
      filter: tableCompanyNoticeFilterInput,
      offset: offset,
      limit: PAGE_LIMIT
    }
    const getCompanyNoticeListResult = await callGetCompanyNoticeList(params)
    if (
      getCompanyNoticeListResult.data &&
      getCompanyNoticeListResult.data.getCompanyNoticeList
    ) {
      const getCompanyNoticeListResultData =
        getCompanyNoticeListResult.data.getCompanyNoticeList
      setCompanyNoticeAndAdminUserList(
        getCompanyNoticeListResultData.data as CompanyNoticeAndCreatedUserAndUpdatedUserDeletedUser[]
      )
      if (getCompanyNoticeListResultData.paging) {
        setCount(getCompanyNoticeListResultData.paging.count)
      }
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  function createTableCompanyNoticeFilterInput(): TableCompanyNoticeFilterInput {
    const param: TableCompanyNoticeFilterInput = {}

    if (searchCondition.body) {
      param.body = { contains: searchCondition.body }
    }

    if (searchCondition.createdAtTo && searchCondition.createdAtFrom) {
      param.created_at = {
        between: [searchCondition.createdAtFrom, searchCondition.createdAtTo]
      }
    }

    if (searchCondition.createdBy) {
      param.created_by = { eq: Number(searchCondition.createdBy) }
    }

    searchConditionDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      searchCondition: {
        companyNotice: { search: param, pageNumber: pageNumber }
      }
    })

    return param
  }

  async function callGetCompanyNoticeList(
    params: GetCompanyNoticeListQueryVariables
  ): Promise<GraphQLResult<GetCompanyNoticeListQuery>> {
    return await CallGraphQL(getCompanyNoticeList, params, false)
  }

  async function fetchAdminUserList() {
    const params: GetAdminUserListQueryVariables = {
      filter: {},
      offset: 0,
      limit: 0
    }
    const getAdminUserListResult = await callGetAdminUserList(params)
    if (getAdminUserListResult.data?.getAdminUserList?.data) {
      const adminUserSelectItemList: SelectItem[] = [{ label: '', value: '' }]
      for (let adminUser of getAdminUserListResult.data?.getAdminUserList
        ?.data) {
        if (adminUser && adminUser.id && adminUser.name) {
          adminUserSelectItemList.push({
            label: adminUser.name,
            value: adminUser.id
          })
        }
      }
      setAdminUserSelectItems(adminUserSelectItemList)
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callGetAdminUserList(
    params: GetAdminUserListQueryVariables
  ): Promise<GraphQLResult<GetAdminUserListQuery>> {
    return await CallGraphQL(getAdminUserList, params, false)
  }

  function searchChips() {
    if (
      !searchCondition?.body &&
      !searchCondition?.createdAtFrom &&
      !searchCondition?.createdAtTo &&
      !searchCondition?.createdBy
    ) {
      return undefined
    }

    let createdByLabel = ''
    if (searchCondition?.createdBy) {
      for (let i of adminUserSelectItems) {
        if (i.value === searchCondition.createdBy) {
          createdByLabel = i.label
          break
        }
      }
    }

    return (
      <>
        {searchCondition?.body && (
          <CommonChip
            label={`本文: ${searchCondition.body}`}
            onDelete={(e) => {
              setBody('')
              delete searchCondition['body']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
        {searchCondition?.createdAtFrom && (
          <CommonChip
            label={`登録日(From): ${searchCondition.createdAtFrom}`}
            onDelete={(e) => {
              setCreatedAtFrom(null)
              setCreatedAtTo(null)
              delete searchCondition['createdAtFrom']
              delete searchCondition['createdAtTo']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
        {searchCondition?.createdAtTo && (
          <CommonChip
            label={`登録日(To): ${searchCondition.createdAtTo}`}
            onDelete={(e) => {
              setCreatedAtFrom(null)
              setCreatedAtTo(null)
              delete searchCondition['createdAtFrom']
              delete searchCondition['createdAtTo']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
        {searchCondition?.createdBy && createdByLabel && (
          <CommonChip
            label={`登録者: ${createdByLabel}`}
            onDelete={(e) => {
              setCreatedBy('')
              delete searchCondition['createdBy']
              setSearchCondition(Object.assign({}, searchCondition))
              setPageNumber(1)
            }}
          />
        )}
      </>
    )
  }

  return (
    <Layout
      title={'管理者向けお知らせ管理'}
      headerComponent={
        <SearchTextField
          searchWord={body}
          setSearchWord={setBody}
          searchPlaceholder={'お知らせ本文を検索'}
          search={search}
          validateSearch={validateSearch}
          searchDetailed={
            <>
              <Grid item xs={12}>
                <TextField
                  label={'本文'}
                  id="body"
                  fullWidth
                  autoComplete="body"
                  variant="filled"
                  value={body}
                  defaultValue={body}
                  onChange={(event) => {
                    setBody(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  label="登録日(From)"
                  format="yyyy-MM-dd"
                  value={createdAtFrom}
                  setValue={setCreatedAtFrom}
                  isError={validate?.createdAtFrom?.isError}
                  helperText={validate?.createdAtFrom?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  label="登録日(To)"
                  format="yyyy-MM-dd"
                  value={createdAtTo}
                  setValue={setCreatedAtTo}
                  isError={validate?.createdAtTo?.isError}
                  helperText={validate?.createdAtTo?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name={'createdBy'}
                  label={'登録者'}
                  defaultValue={createdBy}
                  value={createdBy}
                  setValue={setCreatedBy}
                  items={adminUserSelectItems}
                  hasError={validate?.createdBy?.isError}
                  helperText={validate?.createdBy?.message}
                />
              </Grid>
            </>
          }
        />
      }
    >
      <ListWrapper>
        <SearchConditionChipWrapper chips={searchChips()} />
        <Grid container alignItems={'center'} spacing={3} xs={12}>
          <Grid item xs={3}>
            <LinkButton
              color={'primary'}
              name={'新規追加'}
              to={'/notice/company/edit'}
              startIcon={<AddIcon />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <TablePaginationWrapper
              isLoading={isLoading}
              count={count}
              rowsPerPage={PAGE_LIMIT}
              page={pageNumber - 1}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
        <ListBody isLoading={isLoading}>
          <TableList
            tableHeadColumnList={headColumn}
            handlePageNumber={handleCustomChangePage}
          >
            {companyNoticeAndAdminUserList &&
              companyNoticeAndAdminUserList.length > 0 &&
              companyNoticeAndAdminUserList.map((row) => {
                const id = row.companyNotice ? row.companyNotice.id : '0'
                return (
                  <TableRow
                    onClick={() => handleRowClick(id)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell>
                      {row.companyNotice &&
                        row.companyNotice.body &&
                        CellWithBody(row.companyNotice.body)}
                    </TableCell>
                    <TableCell>
                      {row.companyNotice && row.companyNotice.created_at && (
                        <DateLabel timestamp={row.companyNotice.created_at} />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.createdUser && row.createdUser.name}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableList>
        </ListBody>
        <Pagination
          isLoading={isLoading}
          handlePageNumber={handleCustomChangePage}
          pageNumber={pageNumber}
          count={count}
          rowsPerPage={PAGE_LIMIT}
        />
      </ListWrapper>
    </Layout>
  )
}
