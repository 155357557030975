import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  rootWrapper: {
    width: '100%',
    marginTop: 20
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  section: {
    padding: '15px 20px'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },
  subTitle: {
    marginLeft: '10px',
    height: '18px',
    color: theme.palette.subFontColor.main,
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px'
  }
}))

type Props = {
  title: string
  subTitle?: string
  children: ReactNode
  footer?: ReactNode
}

export function FormLayout({ title, subTitle, children, footer }: Props) {
  const classes = useStyles()
  return (
    <Paper className={classes.rootWrapper}>
      <div className={classes.root}>
        <div className={classes.section}>
          <Grid container alignItems="center">
            <Grid>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
            {subTitle && (
              <Grid>
                <Typography className={classes.subTitle}>{subTitle}</Typography>
              </Grid>
            )}
          </Grid>
        </div>
        <Divider />
        {children && <div className={classes.section}>{children}</div>}
        {footer && (
          <>
            <Divider />
            <div className={classes.section}>{footer}</div>
          </>
        )}
      </div>
    </Paper>
  )
}
