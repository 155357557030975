export function formatDate(type: string, value: string) {
  if (type === 'hmi') return formatDateTime(value)
  if (type === 'ago') return formatDateAgo(value)
  throw new Error('Please give a type')
}

function formatDateAgo(timestamp: string) {
  if (!timestamp) {
    return '不明'
  }
  const targetDatetime = new Date(timestamp)
  const currentDatetime = new Date()

  const diff = currentDatetime.getTime() - targetDatetime.getTime()

  const second = 1000
  const minute = 60 * second
  const hour = 60 * minute
  const day = 24 * hour
  const month = 30 * day
  const year = 365 * day

  if (diff > year) return `${Math.trunc(diff / year)}年前`
  if (diff > month) return `${Math.trunc(diff / month)}ヶ月前`
  if (diff > day) return `${Math.trunc(diff / day)}日前`
  if (diff > hour) return `${Math.trunc(diff / hour)}時間前`
  if (diff > minute) return `${Math.trunc(diff / minute)}分前`

  let result = Math.trunc(diff / second)
  if (result < 0) {
    result = 0
  }
  return `${result}秒前`
}

function formatDateTime(timestamp: string) {
  const targetDatetime = new Date(timestamp)
  const date = formatTwoDigitNumber(targetDatetime.getDate())
  const month = formatTwoDigitNumber(targetDatetime.getMonth() + 1)
  const hour = formatTwoDigitNumber(targetDatetime.getHours())
  const minute = formatTwoDigitNumber(targetDatetime.getMinutes())

  return `${month}/${date} ${hour}:${minute}`
}

function formatTwoDigitNumber(num: number) {
  const numText = num.toString()
  return numText.length < 2 ? '0' + numText : numText
}
