import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles({
  name: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  nameKana: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  }
})

type Props = {
  name: string
  nameKana?: string
}

export function Name({ name, nameKana }: Props) {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      <Grid item className={classes.name}>
        <span>{name}</span>
      </Grid>
      {nameKana && (
        <Grid item className={classes.nameKana}>
          <span>{nameKana}</span>
        </Grid>
      )}
    </Grid>
  )
}
