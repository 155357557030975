import React, { useEffect, useState } from 'react'
import { Layout } from 'views/components/template/Layout'
import { FormLayout } from 'views/components/organisms/FormLayout'
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { ListWrapper } from 'views/components/template/ListWrapper'
import { CallGraphQL } from 'utils/api.utils'
import {
  GetOriginalUrlQuery,
  GetOriginalUrlQueryVariables,
  GetLoginIpAddressListQuery,
  GetLoginIpAddressListQueryVariables,
  CreateOriginalUrlMutation,
  CreateOriginalUrlMutationVariables,
  CreateLoginIpAddressMutation,
  CreateLoginIpAddressMutationVariables,
  DeleteLoginIpAddressMutation,
  DeleteLoginIpAddressMutationVariables,
  GetPackageSizeQuery,
  GetPackageSizeQueryVariables,
  UpdatePacakgeSizeMutation,
  UpdatePacakgeSizeMutationVariables
} from 'API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import {
  getOriginalUrl,
  getLoginIpAddressList,
  getPackageSize
} from 'graphql/queries'
import {
  createOriginalUrl,
  createLoginIpAddress,
  deleteLoginIpAddress,
  updatePacakgeSize
} from 'graphql/mutations'
import { useParams } from 'react-router-dom'
import { ListBody } from 'views/components/template/ListBody'
import { ButtonFooter } from 'views/components/organisms/ButtonFooter'
import { Dropdown } from 'views/components/atoms/Dropdown'
import { Validate } from 'common/Type'

const useStyles = makeStyles({
  label: {
    marginRight: '10px'
  }
})

type Params = {
  id: string
}

type LoginIpAddress = {
  companyId: number
  ipAddress: string
}

type OriginalUrl = {
  companyId: number
  originalUrl: string
}

type PackageSizeValidate = {
  availableSlotSize?: Validate
  topPageDisplaySize?: Validate
}

export function CompanyManagementDetail() {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams<Params>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [originalUrl, setOriginalUrl] = useState<OriginalUrl>()
  const [loginIpAddressList, setLoginIpAddressList] =
    useState<LoginIpAddress[]>()
  const [url, setUrl] = useState('')
  const [ipAddress, setIpAddress] = useState('')
  const [defaultPackageSize, setDefaultPackageSize] = useState({
    availableSlotSize: '',
    topPageDisplaySize: '',
    remark: ''
  })
  const [availableSlotSize, setAvailableSlotSize] = useState('')
  const [topPageDisplaySize, setTopPageDisplaySize] = useState('')
  const [remark, setRemark] = useState('')
  const [validate, setValidate] = useState<PackageSizeValidate>()

  const numbers = Array.from(Array(21).keys()).map((number) => number)

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target
    switch (name) {
      case 'availableSlotSize':
        setAvailableSlotSize(value)
        break
      case 'topPageDisplaySize':
        setTopPageDisplaySize(value)
        break
      case 'remark':
        setRemark(value)
        break
      default:
        break
    }
  }

  function validateForm() {
    const valid: PackageSizeValidate = {}
    if (!availableSlotSize) {
      valid.availableSlotSize = { isError: true, message: '必須です' }
    }
    if (!topPageDisplaySize) {
      valid.topPageDisplaySize = { isError: true, message: '必須です' }
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  const handleCancel = () => {
    if (defaultPackageSize) {
      setAvailableSlotSize(
        defaultPackageSize ? defaultPackageSize.availableSlotSize : '3'
      )
      setTopPageDisplaySize(
        defaultPackageSize ? defaultPackageSize.topPageDisplaySize : '0'
      )
      setRemark(defaultPackageSize ? defaultPackageSize.remark : '')
    } else {
      setAvailableSlotSize('3')
      setTopPageDisplaySize('0')
      setRemark('')
    }
  }

  const fetchOriginalUrl = async () => {
    const params: GetOriginalUrlQueryVariables = {
      companyId: Number(id)
    }
    const response = await callGetOriginalUrl(params)
    const getOriginalUrlResultData = response.data?.getOriginalUrl
    setOriginalUrl(getOriginalUrlResultData as OriginalUrl)
  }

  const fetchIpAddressList = async () => {
    const params: GetLoginIpAddressListQueryVariables = {
      companyId: Number(id)
    }
    const response = await callGetLoginIpAddressList(params)
    const getLoginIpAddressListResultData = response.data?.getLoginIpAddressList
    setLoginIpAddressList(getLoginIpAddressListResultData as LoginIpAddress[])
  }

  const fetchPackageSize = async () => {
    const params: GetPackageSizeQueryVariables = {
      companyId: Number(id)
    }
    const response = await callGetPackageSize(params)
    if (response.data && response.data.getPackageSize) {
      const getPackageSizeResultData = response.data?.getPackageSize
      if (getPackageSizeResultData != null) {
        setDefaultPackageSize({
          availableSlotSize: getPackageSizeResultData.available_slot_size
            ? getPackageSizeResultData.available_slot_size.toString()
            : '',
          topPageDisplaySize:
            getPackageSizeResultData.top_page_display_size != null
              ? getPackageSizeResultData.top_page_display_size.toString()
              : '',
          remark: getPackageSizeResultData.remark
            ? getPackageSizeResultData.remark
            : ''
        })
        setAvailableSlotSize(
          getPackageSizeResultData.available_slot_size != null
            ? getPackageSizeResultData.available_slot_size.toString()
            : ''
        )
        setTopPageDisplaySize(
          getPackageSizeResultData.top_page_display_size != null
            ? getPackageSizeResultData.top_page_display_size.toString()
            : ''
        )
        setRemark(
          getPackageSizeResultData.remark ? getPackageSizeResultData.remark : ''
        )
      }
    } else {
      enqueueSnackbar('データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  const handleCreateOriginalUrl = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    if (url) {
      if (!window.confirm('設定後はURLの変更ができません。よろしいですか。')) {
        return
      }
      const params: CreateOriginalUrlMutationVariables = {
        input: {
          companyId: Number(id),
          originalUrl: url
        }
      }
      const response = await callCreateOriginalUrl(params)
      if (response.data) {
        enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        fetchData()
      } else {
        enqueueSnackbar('保存に失敗しました', {
          variant: 'error'
        })
      }
    } else {
      enqueueSnackbar('URLが入力されていません', {
        variant: 'error'
      })
    }
  }

  const handleCreateIpAddress = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    if (ipAddress) {
      const regex =
        /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/
      if (ipAddress.match(regex)) {
        const params: CreateLoginIpAddressMutationVariables = {
          input: {
            companyId: Number(id),
            ipAddress: ipAddress
          }
        }
        const response = await callCreateLoginIpAddress(params)
        if (response.data) {
          enqueueSnackbar('保存しました', {
            variant: 'success'
          })
          fetchData()
        } else {
          enqueueSnackbar('保存に失敗しました', {
            variant: 'error'
          })
        }
      } else {
        enqueueSnackbar('入力された値はIPアドレスの形式ではありません', {
          variant: 'error'
        })
      }
    } else {
      enqueueSnackbar('IPアドレスが入力されていません', {
        variant: 'error'
      })
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const params: UpdatePacakgeSizeMutationVariables = {
      input: {
        company_id: Number(id),
        available_slot_size: Number(availableSlotSize),
        top_page_display_size: Number(topPageDisplaySize),
        remark: remark
      }
    }

    if (validateForm()) {
      enqueueSnackbar('入力内容に誤りがあります', {
        variant: 'warning'
      })
      return
    }

    if (
      params.input.top_page_display_size != null &&
      params.input.available_slot_size != null
    ) {
      if (
        params.input.top_page_display_size > params.input.available_slot_size
      ) {
        enqueueSnackbar(
          'TOPページ掲載可能数は入居者サービス使用枠より少なくする必要があります。',
          {
            variant: 'error'
          }
        )
        setIsLoading(false)
        return
      }
    }

    const message = '入居者サービスプラン情報を保存します。よろしいですか？'
    if (
      params.input.available_slot_size == null ||
      defaultPackageSize.availableSlotSize == null ||
      params.input.top_page_display_size == null ||
      defaultPackageSize.topPageDisplaySize == null
    ) {
      if (!window.confirm(message)) {
        return
      }
    } else if (
      params.input.available_slot_size >=
        Number(defaultPackageSize.availableSlotSize) &&
      params.input.top_page_display_size >=
        Number(defaultPackageSize.topPageDisplaySize)
    ) {
      if (!window.confirm(message)) {
        return
      }
    } else if (
      params.input.available_slot_size <
        Number(defaultPackageSize.availableSlotSize) &&
      params.input.top_page_display_size <
        Number(defaultPackageSize.topPageDisplaySize)
    ) {
      if (
        !window.confirm(
          `${message}\n※入居者サービス使用枠数を減らした場合、全て未使用状態となります。\n※TOPページ掲載可能数を減らした場合、全てTOP表示OFFの状態となります。`
        )
      ) {
        return
      }
    } else if (
      params.input.available_slot_size <
      Number(defaultPackageSize.availableSlotSize)
    ) {
      if (
        !window.confirm(
          `${message}\n※入居者サービス使用枠数を減らした場合、全て未使用状態となります。`
        )
      ) {
        return
      }
    } else if (
      params.input.top_page_display_size <
      Number(defaultPackageSize.topPageDisplaySize)
    ) {
      if (
        !window.confirm(
          `${message}\n※TOPページ掲載可能数を減らした場合、全てTOP表示OFFの状態となります。`
        )
      ) {
        return
      }
    }

    const response = await callUpdatePackageSize(params)
    if (response.data) {
      enqueueSnackbar('保存しました', {
        variant: 'success'
      })
      fetchData()
    } else {
      enqueueSnackbar('保存に失敗しました', {
        variant: 'error'
      })
    }
  }

  const handleDeleteIpAddress = async (
    event: React.FormEvent<HTMLFormElement>,
    ipAddress: string
  ) => {
    event.preventDefault()
    if (!window.confirm(`削除します。よろしいですか？`)) {
      return
    }
    const params: DeleteLoginIpAddressMutationVariables = {
      input: {
        companyId: Number(id),
        ipAddress: ipAddress
      }
    }
    const response = await callDeleteLoginIpAddress(params)
    if (response.data) {
      enqueueSnackbar('削除しました', {
        variant: 'success'
      })
      fetchData()
    } else {
      enqueueSnackbar('削除に失敗しました', {
        variant: 'error'
      })
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([
      fetchOriginalUrl(),
      fetchIpAddressList(),
      fetchPackageSize()
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  async function callGetOriginalUrl(
    params: GetOriginalUrlQueryVariables
  ): Promise<GraphQLResult<GetOriginalUrlQuery>> {
    return await CallGraphQL(getOriginalUrl, params, false)
  }
  async function callGetLoginIpAddressList(
    params: GetLoginIpAddressListQueryVariables
  ): Promise<GraphQLResult<GetLoginIpAddressListQuery>> {
    return await CallGraphQL(getLoginIpAddressList, params, false)
  }
  async function callCreateOriginalUrl(
    params: CreateOriginalUrlMutationVariables
  ): Promise<GraphQLResult<CreateOriginalUrlMutation>> {
    return await CallGraphQL(createOriginalUrl, params, false)
  }
  async function callCreateLoginIpAddress(
    params: CreateLoginIpAddressMutationVariables
  ): Promise<GraphQLResult<CreateLoginIpAddressMutation>> {
    return await CallGraphQL(createLoginIpAddress, params, false)
  }
  async function callDeleteLoginIpAddress(
    params: DeleteLoginIpAddressMutationVariables
  ): Promise<GraphQLResult<DeleteLoginIpAddressMutation>> {
    return await CallGraphQL(deleteLoginIpAddress, params, false)
  }
  async function callGetPackageSize(
    params: GetPackageSizeQueryVariables
  ): Promise<GraphQLResult<GetPackageSizeQuery>> {
    return await CallGraphQL(getPackageSize, params, false)
  }
  async function callUpdatePackageSize(
    params: UpdatePacakgeSizeMutationVariables
  ): Promise<GraphQLResult<UpdatePacakgeSizeMutation>> {
    return await CallGraphQL(updatePacakgeSize, params, false)
  }

  return (
    <Layout title={'設定情報'} backEnabled={true}>
      <ListWrapper>
        <ListBody isLoading={isLoading}>
          {(() => {
            if (originalUrl) {
              return (
                <FormLayout
                  title={'利用者URL'}
                  subTitle={
                    'https://mng-web.totono.app/' +
                    originalUrl.originalUrl +
                    '/authorize/signin'
                  }
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Grid item xs={12}>
                        <h3>IP制限登録</h3>
                      </Grid>
                    </Grid>
                    <Grid item xs={10}>
                      {loginIpAddressList &&
                        loginIpAddressList.length > 0 &&
                        loginIpAddressList.map((row) => {
                          return (
                            <Grid item xs={12}>
                              <form
                                onSubmit={(e) =>
                                  handleDeleteIpAddress(e, row.ipAddress)
                                }
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <p>{row.ipAddress}</p>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button
                                      type="submit"
                                      style={{
                                        color: '#fff',
                                        backgroundColor: '#778698'
                                      }}
                                    >
                                      削除
                                    </Button>
                                  </Grid>
                                </Grid>
                              </form>
                            </Grid>
                          )
                        })}
                      <Grid item xs={12}>
                        <form onSubmit={(e) => handleCreateIpAddress(e)}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <TextField
                                label={'新規IpAddress'}
                                id="ipAddress"
                                name="ipAddress"
                                onChange={(e) => setIpAddress(e.target.value)}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                type="submit"
                                style={{
                                  color: '#fff',
                                  backgroundColor: '#0f8cf0'
                                }}
                              >
                                追加
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormLayout>
              )
            } else {
              return (
                <FormLayout title={'利用者URL'}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <form onSubmit={(e) => handleCreateOriginalUrl(e)}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              label={'利用者URL'}
                              id="originalUrl"
                              name="originalUrl"
                              onChange={(e) => setUrl(e.target.value)}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              type="submit"
                              style={{
                                color: '#fff',
                                backgroundColor: '#0f8cf0'
                              }}
                            >
                              追加
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </Grid>
                  </Grid>
                </FormLayout>
              )
            }
          })()}
        </ListBody>
        <ListBody isLoading={isLoading}>
          <FormLayout title={'入居者サービスプラン'}>
            <Grid container>
              <Grid item xs={4}>
                <Grid container alignItems="center">
                  <Typography className={classes.label}>
                    入居者サービス使用枠数
                  </Typography>
                  <Grid>
                    <Dropdown
                      name="availableSlotSize"
                      label=""
                      defaultValue={availableSlotSize}
                      value={availableSlotSize}
                      setValue={setAvailableSlotSize}
                      items={numbers.map((n) => ({
                        value: n.toString(),
                        label: n.toString()
                      }))}
                      hasError={validate?.availableSlotSize?.isError}
                      helperText={validate?.availableSlotSize?.message}
                      hiddenLabel
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container alignItems="center">
                  <Typography className={classes.label}>
                    TOPページ掲載可能数
                  </Typography>
                  <Grid>
                    <Dropdown
                      name="topPageDisplaySize"
                      label=""
                      defaultValue={topPageDisplaySize}
                      value={topPageDisplaySize}
                      setValue={setTopPageDisplaySize}
                      items={numbers.map((n) => ({
                        value: n.toString(),
                        label: n.toString()
                      }))}
                      hasError={validate?.topPageDisplaySize?.isError}
                      helperText={validate?.topPageDisplaySize?.message}
                      hiddenLabel
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container alignItems="center">
                  <Typography className={classes.label}>備考</Typography>
                  <Grid item xs>
                    <TextField
                      variant="filled"
                      id="remark"
                      name="remark"
                      onChange={handleChange}
                      value={remark}
                      inputProps={{
                        maxLength: 100
                      }}
                      hiddenLabel
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <form
              noValidate
              autoComplete={'off'}
              onSubmit={(e) => handleSubmit(e)}
            >
              <ButtonFooter handleCancel={handleCancel} />
            </form>
          </FormLayout>
        </ListBody>
      </ListWrapper>
    </Layout>
  )
}
