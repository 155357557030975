import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

type Props = {
  isLoading: boolean
  marginTop?: number
  marginBottom?: number
}

const useStyles = makeStyles<Theme, Props>((theme) => {
  return {
    loading: {
      textAlign: 'center'
    },
    circularProgress: {
      marginTop: (props) => (props.marginTop ? props.marginTop : '0'),
      marginBottom: (props) => (props.marginBottom ? props.marginBottom : '0')
    }
  }
})

export const Loading = React.memo((props: Props) => {
  const classes = useStyles(props)
  const { isLoading } = props

  return (
    <>
      {isLoading && (
        <Grid container xs={12} justify={'center'}>
          <div className={classes.loading}>
            <CircularProgress className={classes.circularProgress} />
          </div>
        </Grid>
      )}
    </>
  )
})
