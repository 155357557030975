import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { SvgIconProps } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondaryButtonColor.main,
    backgroundColor: theme.palette.secondaryButtonBgColor.main,
    '&:hover': {
      backgroundColor: theme.palette.secondaryButtonHoverColor.main
    },
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

type Props = {
  text: string
  handleClick: React.MouseEventHandler<HTMLButtonElement>
  startIcon?: React.HTMLProps<SvgIconProps>
  endIcon?: React.HTMLProps<SvgIconProps>
}

export const SecondaryButton = React.memo(
  ({ text, handleClick, startIcon, endIcon }: Props) => {
    const classes = useStyles()

    return (
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleClick}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {text}
      </Button>
    )
  }
)
