import React, { Dispatch, SetStateAction, useState } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import TextField from '@material-ui/core/TextField'
import { PropTypes } from '@material-ui/core'

type Props = {
  label?: string
  password?: string
  setPassword: Dispatch<SetStateAction<string>>
  isError?: boolean
  helperText?: string
  isRequired?: boolean
  margin?: PropTypes.Margin
  autoComplete?: string
}

export const PasswordText = React.memo(
  ({
    label = 'パスワード',
    password,
    setPassword,
    isError,
    helperText,
    isRequired = true,
    margin = 'normal',
    autoComplete
  }: Props) => {
    const [isShowPassword, setIsShowPassword] = useState(false)
    const handleChangeShowPassword = () => {
      setIsShowPassword(!isShowPassword)
    }

    return (
      <>
        <TextField
          autoComplete={autoComplete}
          variant="filled"
          margin={margin}
          required={isRequired}
          fullWidth
          id="password"
          name="password"
          label={label}
          type={isShowPassword ? 'text ' : 'password'}
          onChange={(event) => setPassword(event.target.value)}
          error={isError}
          helperText={helperText}
          defaultValue={password}
          value={password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleChangeShowPassword}
                >
                  {isShowPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </>
    )
  }
)
