import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { GetAdminUserQuery, GetAdminUserQueryVariables } from 'API'
import { AdminUser } from 'common/Type'
import { useSnackbar } from 'notistack'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { CallGraphQL } from 'utils/api.utils'
import { getAdminUser } from 'graphql/queries'
import { SearchConditionContext } from '../../../contexts/SearchConditionContext'
import { AdminUserContext } from '../../../contexts/AdminUserContext'

const env = process.env.REACT_APP_ENV

const useStyles = makeStyles({
  titleWrapper: {
    marginLeft: 10,
    alignContent: 'center'
  },
  title: {
    color: '#fff',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    alignItems: 'left'
  },
  img: {
    width: 40,
    height: 40,
    alignItems: 'center'
  }
})

type Props = {
  className?: string
}

export const IconAndUserName = React.memo(({ className }: Props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { adminUserState, adminUserDispatch } = useContext(AdminUserContext)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    await Promise.all([adminUserState?.adminUser ? null : fetchAdminUserList()])
  }

  async function fetchAdminUserList() {
    const item = sessionStorage.getItem(`totono-admin-${env}-session`)
      ? sessionStorage.getItem(`totono-admin-${env}-session`)
      : localStorage.getItem(`totono-admin-${env}-session`)
    if (!item) {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
      return
    }
    const data = JSON.parse(item)
    const params: GetAdminUserQueryVariables = {
      filter: { id: { eq: data.user_id } }
    }
    const getAdminUserResult = await callAdminUser(params)
    if (getAdminUserResult.data?.getAdminUser) {
      adminUserDispatch({
        type: 'SET', // reducerで指定したtypeを使う
        adminUser: getAdminUserResult.data.getAdminUser as AdminUser
      })
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callAdminUser(
    params: GetAdminUserQueryVariables
  ): Promise<GraphQLResult<GetAdminUserQuery>> {
    return await CallGraphQL(getAdminUser, params, true)
  }

  return (
    <div className={className}>
      <img
        className={classes.img}
        src={`${process.env.PUBLIC_URL}/favicon.png`}
        alt={'totono'}
      />
      <Grid container className={classes.titleWrapper}>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            {adminUserState?.adminUser?.name}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
})
