import React, { ReactNode, useState, useRef, useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { Divider } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { IconAndUserName } from 'views/components/molecules/IconAndUserName'
import { UserMenu } from 'views/components/molecules/UserMenu'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles<Theme, Props>((theme) => {
  return {
    '@global': {
      '.MuiAppBar-root': {
        height: '64px'
      },
      '.MuiAppBar-colorPrimary': {
        backgroundColor: theme.palette.primaryColor.main
      },
      '.MuiDrawer-paper': {
        maxHeight: '600px'
      }
    },
    appBar: {
      width: '100%',
      boxShadow: 'none'
    },
    toolbar: {
      flex: 1
    },
    iconAndUserName: {
      width: '231px',
      display: 'flex'
    },
    back: {
      marginLeft: '16px',
      color: '#fff'
    },
    title: {
      flex: 1,
      marginLeft: (props) => (props.backEnabled ? 0 : '32px'),
      color: '#ffffff',
      fontFamily: 'Hiragino Sans',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px'
    },
    accountCircle: {
      marginLeft: '20px',
      color: theme.palette.white.main
    },
    list: {
      width: '400px'
    },
    container: {
      maxHeight: 540,
      maxWidth: '100%'
    }
  }
})

type Props = {
  title: string
  backEnabled?: boolean
  headerComponent?: ReactNode
}

export function Header({ title, backEnabled = false, headerComponent }: Props) {
  const classes = useStyles({ title, backEnabled })
  const history = useHistory()
  const [drawerType, setDrawerType] = useState<string>('')
  const anchorRef = useRef(null)

  useEffect(() => {}, [title])

  function handleOpen(str: string) {
    if (str === drawerType) {
      setDrawerType('')
    } else {
      setDrawerType(str)
    }
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconAndUserName className={classes.iconAndUserName} />
        <Divider orientation="vertical" />
        {backEnabled && (
          <IconButton
            aria-label="back"
            className={classes.back}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography className={classes.title}>{title}</Typography>
        {headerComponent && headerComponent}
        <IconButton
          className={classes.accountCircle}
          onClick={() => handleOpen('userMenu')}
          ref={anchorRef}
        >
          <AccountCircleIcon />
        </IconButton>
      </Toolbar>
      {drawerType === 'userMenu' && (
        <UserMenu
          anchorRef={anchorRef}
          open={true}
          onClose={() => handleOpen('')}
        />
      )}
    </AppBar>
  )
}
