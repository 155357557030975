import React, { useReducer, createContext, ReactNode } from 'react'
import { State, Action, lastPathReducer } from 'reducers/LastPathReducer'

type ContextType = {
  lastPathState: State
  lastPathDispatch: React.Dispatch<Action>
}

export const LastPathContext = createContext({} as ContextType)

type Props = {
  children: ReactNode
}

const initialState = { lastPath: '/' }

export function LastPathProvider({ children }: Props) {
  const [lastPathState, lastPathDispatch] = useReducer(
    lastPathReducer,
    initialState
  )

  return (
    <LastPathContext.Provider value={{ lastPathState, lastPathDispatch }}>
      {children}
    </LastPathContext.Provider>
  )
}
