import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Cache } from 'aws-amplify'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Grid } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

const env = process.env.REACT_APP_ENV

const useStyles = makeStyles({
  '.MuiDrawer-paper': {
    maxHeight: '600px'
  },
  menu: {
    border: '1px solid #d3d4d5'
  },
  password: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    lineHeight: '21px'
  },
  divider: {
    marginBottom: '7px'
  }
})

type Props = {
  anchorRef: any
  open: boolean
  onClose: Dispatch<SetStateAction<string>>
}

export function UserMenu(props: Props) {
  const history = useHistory()
  const classes = useStyles()

  const { anchorRef, open, onClose } = props
  const [companyUser, setCompanyUser] = useState()

  useEffect(() => {}, [])

  const handleSignOut = () => {
    localStorage.removeItem(`totono-admin-${env}-session`)
    sessionStorage.removeItem(`totono-admin-${env}-session`)
    Cache.clear()
    history.push('/authorize/signin')
  }

  return (
    <>
      <Menu
        className={classes.menu}
        anchorEl={anchorRef.current}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        keepMounted
        open={open}
        onClose={onClose}
      >
        <MenuItem
          className={classes.password}
          // onClick={() => history.push(`/setting/company-user/edit/${companyUser.id}`)}
        >
          <Grid direction="column">
            <Grid item>パスワード変更</Grid>
          </Grid>
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem>
          <Grid item>
            <Button onClick={handleSignOut} variant="outlined">
              ログアウト
            </Button>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  )
}
