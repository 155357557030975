import React from 'react'

export type State = {
  lastPath: string
}

export type Action = {
  type: 'SET'
  lastPath: string
}

export function lastPathReducer(state: State, action: Action) {
  if (action.type === 'SET') {
    return { ...state, lastPath: action.lastPath }
  }
  throw new Error()
}
