// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const env = process.env.REACT_APP_ENV;
let awsmobile = {};
if (env === "production") {
    awsmobile = {
        "aws_project_region": "ap-northeast-1",
        "aws_appsync_graphqlEndpoint": "https://4cclykqscbe2diq5g5dbh3lbgi.appsync-api.ap-northeast-1.amazonaws.com/graphql",
        "aws_appsync_region": "ap-northeast-1",
        "aws_appsync_authenticationType": "AWS_IAM",
        "aws_cognito_identity_pool_id": "ap-northeast-1:3d1e48de-ef19-4fc2-973a-a34da390beab",
        "aws_cognito_region": "ap-northeast-1",
        "aws_user_files_s3_bucket": "totono-dev",
        "aws_user_files_s3_bucket_region": "ap-northeast-1",
    };
} else {
    awsmobile = {
        "aws_project_region": "ap-northeast-1",
        "aws_appsync_graphqlEndpoint": "https://ffbloozyhfhr3obzhah42wqz7e.appsync-api.ap-northeast-1.amazonaws.com/graphql",
        "aws_appsync_region": "ap-northeast-1",
        "aws_appsync_authenticationType": "AWS_IAM",
        "aws_cognito_identity_pool_id": "ap-northeast-1:8b508b9f-85c9-4812-be69-ba739ea24344",
        "aws_cognito_region": "ap-northeast-1",
        "aws_user_files_s3_bucket": "totono-prod",
        "aws_user_files_s3_bucket_region": "ap-northeast-1"
    }
}


export default awsmobile;
