import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import Divider from '@material-ui/core/Divider'

import { KeyStringValue, Validate } from 'common/Type'
import {
  AdminAuthSigninMutation,
  AdminAuthSigninMutationVariables,
  AdminTokenRefreshMutation,
  AdminTokenRefreshMutationVariables
} from 'API'
import { adminAuthSignin, adminTokenRefresh } from 'graphql/mutations'

import { LastPathContext } from 'contexts/LastPathContext'
import { LoadingContext } from 'contexts/LoadingContext'

import { PasswordText } from 'views/components/atoms/PasswordText'
import { CallGraphQL } from 'utils/api.utils'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { Amplify } from 'aws-amplify'

const AWS = require('aws-sdk')
AWS.config.region = process.env.REACT_APP_AWS_REGION
const roleArn = process.env.REACT_APP_AWS_COGNITO_ROLE_ARN
const customHeaderName = process.env.REACT_APP_HEADER_WEB_IDENTITY_TOKEN
const env = process.env.REACT_APP_ENV

const useStyles = makeStyles((theme) => ({
  bodyWrapper: {
    height: '100vh',
    backgroundColor: theme.palette.primaryColor.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  body: {
    width: 465,
    height: 465
  },
  content: {
    backgroundColor: '#ffffff'
  },
  paper: {
    margin: theme.spacing(5, 6)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primaryColor.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  border: {
    margin: theme.spacing(4, 0)
  }
}))

type SigninValidate = {
  email?: Validate
  password?: Validate
}

export function Signin() {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { lastPathState } = useContext(LastPathContext)
  const { loadingDispatch } = useContext(LoadingContext)

  const [validate, setValidate] = useState<SigninValidate>()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isAuth, setIsAuth] = useState(false)

  useEffect(() => {
    initAuth()
  }, [])

  async function initAuth() {
    let item = localStorage.getItem(`totono-admin-${env}-session`)
    if (!item) {
      item = sessionStorage.getItem(`totono-admin-${env}-session`)
    }
    if (!item) {
      return
    }

    setIsAuth(true)

    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: true
    })

    const data = JSON.parse(item)
    AWS.config.credentials = new AWS.WebIdentityCredentials({
      RoleArn: roleArn,
      WebIdentityToken: data.token,
      User: {
        Id: data.user_id
      },
      DurationSeconds: 3600
    })

    AWS.config.credentials.get(async function (err: Error) {
      if (err) {
        console.log(JSON.stringify(err))
        AWS.config.credentials = null

        // refreshする
        await refreshToken(data.identityId, data.token)
        loadingDispatch({
          type: 'SET', // reducerで指定したtypeを使う
          isLoading: false
        })
        setIsAuth(false)
        return
      }
      if (AWS.config.credentials.needsRefresh()) {
        AWS.config.credentials.refresh(async function (err: Error) {
          if (err) {
            console.log(JSON.stringify(err))
            loadingDispatch({
              type: 'SET', // reducerで指定したtypeを使う
              isLoading: false
            })
            return
          }
          // refreshする
          const isRefresh = await refreshToken(data.identityId, data.token)

          if (!isRefresh) {
            return
          }

          if (lastPathState && lastPathState.lastPath) {
            history.push(lastPathState.lastPath)
          } else {
            history.push('/')
          }
          return
        })
      } else {
        const customHeader: KeyStringValue = {}
        if (customHeaderName) {
          customHeader[customHeaderName!] = data.token
        } else {
          console.log('ccccc')
          console.log('dddddd')
        }
        Amplify.configure({
          API: {
            graphql_headers: async () => customHeader
          }
        })
        loadingDispatch({
          type: 'SET', // reducerで指定したtypeを使う
          isLoading: false
        })
        console.log('lastPathState', lastPathState)
        if (lastPathState && lastPathState.lastPath) {
          history.push(lastPathState.lastPath)
        } else {
          history.push('/')
        }
      }
    })
  }

  async function refreshToken(
    identityId: string,
    token: string
  ): Promise<boolean> {
    const params: AdminTokenRefreshMutationVariables = {
      identityId: identityId,
      token: token
    }
    const adminTokenRefreshResult = await callAdminTokenRefresh(params)
    if (
      adminTokenRefreshResult.errors ||
      !adminTokenRefreshResult.data ||
      !adminTokenRefreshResult.data.adminTokenRefresh
    ) {
      loadingDispatch({
        type: 'SET', // reducerで指定したtypeを使う
        isLoading: false
      })
      enqueueSnackbar('認証に失敗しました', {
        variant: 'error'
      })
      setIsAuth(false)
      return false
    }
    const adminTokenRefreshData = adminTokenRefreshResult.data.adminTokenRefresh
    await setAwsConfigCredentials(
      adminTokenRefreshData.token,
      adminTokenRefreshData.user_id,
      adminTokenRefreshData
    )
    return true
  }

  async function setAwsConfigCredentials(
    token: string,
    userId: number,
    data: any
  ) {
    AWS.config.credentials = new AWS.WebIdentityCredentials({
      RoleArn: roleArn,
      WebIdentityToken: token,
      User: {
        Id: userId
      },
      DurationSeconds: 3600
    })
    AWS.config.credentials.get(function (err: Error) {
      if (err) {
        console.log(JSON.stringify(err))
        loadingDispatch({
          type: 'SET', // reducerで指定したtypeを使う
          isLoading: false
        })
        setIsAuth(false)
        return
      } else {
        const customHeader: KeyStringValue = {}
        if (customHeaderName) {
          customHeader[customHeaderName!] = token
        } else {
          console.log('aaaaaaaaa')
          console.log('aaabbbbbbbbaaaaaa')
        }

        Amplify.configure({
          API: {
            graphql_headers: async () => customHeader
          }
        })

        console.log('customHeader', customHeader)
        localStorage.setItem(
          `totono-admin-${env}-session`,
          JSON.stringify(data)
        )
        sessionStorage.setItem(
          `totono-admin-${env}-session`,
          JSON.stringify(data)
        )
        loadingDispatch({
          type: 'SET', // reducerで指定したtypeを使う
          isLoading: false
        })
        if (lastPathState && lastPathState.lastPath) {
          history.push(lastPathState.lastPath)
        } else {
          history.push('/')
        }
      }
    })
  }

  async function callAdminTokenRefresh(
    params: AdminTokenRefreshMutationVariables
  ): Promise<GraphQLResult<AdminTokenRefreshMutation>> {
    return await CallGraphQL(adminTokenRefresh, params, false)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()

    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: true
    })

    if (validateForm()) {
      loadingDispatch({
        type: 'SET', // reducerで指定したtypeを使う
        isLoading: false
      })
      enqueueSnackbar('入力内容に誤りがあります', {
        variant: 'warning'
      })
      return
    }

    const params: AdminAuthSigninMutationVariables = {
      loginId: email,
      password: password
    }
    auth(params)
    return
  }

  async function auth(params: AdminAuthSigninMutationVariables) {
    const adminAuthSigninResult = await callAdminAuthSignin(params)
    if (
      !adminAuthSigninResult.data ||
      !adminAuthSigninResult.data.adminAuthSignin
    ) {
      loadingDispatch({
        type: 'SET', // reducerで指定したtypeを使う
        isLoading: false
      })
      enqueueSnackbar('ログインに失敗しました', {
        variant: 'error'
      })
      return
    }
    const adminAuthSigninData = adminAuthSigninResult.data.adminAuthSignin
    await setAwsConfigCredentials(
      adminAuthSigninData.token,
      adminAuthSigninData.user_id,
      adminAuthSigninData
    )
  }

  async function callAdminAuthSignin(
    params: AdminAuthSigninMutationVariables
  ): Promise<GraphQLResult<AdminAuthSigninMutation>> {
    return await CallGraphQL(adminAuthSignin, params, false)
  }

  function validateForm() {
    const valid: SigninValidate = {}
    if (!email || email === '') {
      valid.email = { isError: true, message: 'メールアドレスは必須です' }
    }
    if (!password || password === '') {
      valid.password = {}
      valid.password = { isError: true, message: 'パスワードは必須です' }
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  return (
    <>
      {!isAuth && (
        <div className={classes.bodyWrapper}>
          <Grid container className={classes.body}>
            <CssBaseline />
            <Grid item className={classes.content}>
              <div>
                <div className={classes.paper}>
                  <Grid container>
                    <Grid item>
                      <Avatar variant="rounded" className={classes.avatar}>
                        <VpnKeyIcon />
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography component="h2" variant="h6">
                        Login
                      </Typography>
                      <Typography
                        variant="body2"
                        component="h2"
                        color="primary"
                      >
                        totono管理画面へログイン
                      </Typography>
                    </Grid>
                  </Grid>
                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <TextField
                      variant="filled"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="メールアドレス"
                      name="email"
                      autoFocus
                      onChange={(event) => setEmail(event.target.value)}
                      error={
                        validate && validate.email && validate.email.isError
                      }
                      helperText={
                        validate && validate.email && validate.email.message
                          ? validate.email.message
                          : null
                      }
                    />
                    <PasswordText
                      password={password}
                      setPassword={setPassword}
                      isError={
                        validate &&
                        validate.password &&
                        validate.password.isError
                      }
                      helperText={
                        validate &&
                        validate.password &&
                        validate.password.message
                      }
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      ログイン
                    </Button>
                  </form>
                </div>
                <Divider className={classes.border} />
                <Grid container justify="center">
                  <Grid item>
                    <Link href="#" variant="body2">
                      パスワードを忘れた場合は管理者に連絡してください
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}
