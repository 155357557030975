import React from 'react'

export type State = {
  isLoading: boolean
}

export type Action = {
  type: 'SET'
  isLoading: boolean
}

export function loadingReducer(state: State, action: Action) {
  if (action.type === 'SET') {
    return { ...state, isLoading: action.isLoading }
  }
  throw new Error()
}
