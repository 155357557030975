/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminAuthSignin = /* GraphQL */ `
  mutation AdminAuthSignin($loginId: String!, $password: String!) {
    adminAuthSignin(loginId: $loginId, password: $password) {
      identityId
      token
      user_id
    }
  }
`
export const adminAdvertiserAuthProxySignin = /* GraphQL */ `
  mutation AdminAdvertiserAuthProxySignin($companyId: String!) {
    adminAdvertiserAuthProxySignin(companyId: $companyId) {
      company_id
      identityId
      role_id
      sub_domain
      token
      user_id
      is_administrator
    }
  }
`
export const adminTokenRefresh = /* GraphQL */ `
  mutation AdminTokenRefresh($identityId: String!, $token: String!) {
    adminTokenRefresh(identityId: $identityId, token: $token) {
      identityId
      token
      user_id
    }
  }
`
export const createCompanyNotice = /* GraphQL */ `
  mutation CreateCompanyNotice($input: CreateCompanyNoticeInput!) {
    createCompanyNotice(input: $input) {
      body
      created_at
      created_by
      deleted_at
      deleted_by
      id
      status
      updated_at
      updated_by
      url
    }
  }
`
export const updateCompanyNotice = /* GraphQL */ `
  mutation UpdateCompanyNotice($input: UpdateCompanyNoticeInput!) {
    updateCompanyNotice(input: $input) {
      body
      created_at
      created_by
      deleted_at
      deleted_by
      id
      status
      updated_at
      updated_by
      url
    }
  }
`
export const createCustomerNotice = /* GraphQL */ `
  mutation CreateCustomerNotice($input: CreateCustomerNoticeInput!) {
    createCustomerNotice(input: $input) {
      body
      created_at
      created_by
      deleted_at
      deleted_by
      id
      status
      updated_at
      updated_by
      url
    }
  }
`
export const updateCustomerNotice = /* GraphQL */ `
  mutation UpdateCustomerNotice($input: UpdateCustomerNoticeInput!) {
    updateCustomerNotice(input: $input) {
      body
      created_at
      created_by
      deleted_at
      deleted_by
      id
      status
      updated_at
      updated_by
      url
    }
  }
`
export const createAdminUser = /* GraphQL */ `
  mutation CreateAdminUser($input: CreateAdminUserInput!) {
    createAdminUser(input: $input) {
      id
      status
      username
      password
      name
      created_at
      updated_at
      deleted_at
    }
  }
`
export const updateAdminUser = /* GraphQL */ `
  mutation UpdateAdminUser($input: UpdateAdminUserInput!) {
    updateAdminUser(input: $input) {
      id
      status
      username
      password
      name
      created_at
      updated_at
      deleted_at
    }
  }
`
export const createDataLinkHistory = /* GraphQL */ `
  mutation CreateDataLinkHistory($input: CreateDataLinkHistoryInput!) {
    createDataLinkHistory(input: $input) {
      data_link_history {
        id
        status
        company_id
        data_link_type_id
        data_link_state_id
        data_type_id
        attachment_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      error_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const updateDataLinkHistory = /* GraphQL */ `
  mutation UpdateDataLinkHistory($input: UpdateDataLinkHistoryInput!) {
    updateDataLinkHistory(input: $input) {
      data_link_history {
        id
        status
        company_id
        data_link_type_id
        data_link_state_id
        data_type_id
        attachment_id
        created_at
        updated_at
        deleted_at
      }
      attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
      error_attachment {
        id
        status
        company_id
        attachment_type_id
        mime_type
        filename
        bucket
        key
        convert_key
        thumbnail_key
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
export const createLoginIpAddress = /* GraphQL */ `
  mutation CreateLoginIpAddress($input: createLoginIpAddressInput!) {
    createLoginIpAddress(input: $input) {
      companyId
      ipAddress
    }
  }
`
export const deleteLoginIpAddress = /* GraphQL */ `
  mutation DeleteLoginIpAddress($input: deleteLoginIpAddressInput!) {
    deleteLoginIpAddress(input: $input) {
      body
    }
  }
`
export const createOriginalUrl = /* GraphQL */ `
  mutation CreateOriginalUrl($input: originalUrlInput!) {
    createOriginalUrl(input: $input) {
      companyId
      originalUrl
    }
  }
`
export const updateOriginalUrl = /* GraphQL */ `
  mutation UpdateOriginalUrl($input: originalUrlInput!) {
    updateOriginalUrl(input: $input) {
      companyId
      originalUrl
    }
  }
`
export const updatePacakgeSize = /* GraphQL */ `
  mutation UpdatePacakgeSize($input: UpdatePackageSizeInput!) {
    updatePacakgeSize(input: $input) {
      available_slot_size
      top_page_display_size
      remark
    }
  }
`
