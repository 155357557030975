import React, { MouseEventHandler } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles({
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:nth-child(n+2)': {
      marginTop: 10
    }
  },
  imageRow: {
    display: 'flex',
    alignItems: 'center'
  },
  iconRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 56,
    backgroundColor: '#EDF1F5'
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: 'auto',
    marginLeft: 20,
    fontWeight: 'bold',
    fontSize: '16px'
  }
})

type Props = {
  fileTitle: string
  handleDelete?: MouseEventHandler<HTMLButtonElement>
}

export const File = React.memo(({ fileTitle, handleDelete }: Props) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.imageContainer}>
      <Grid xs={11} item>
        <Grid container className={classes.imageRow}>
          <Grid item className={classes.iconRow}>
            <DescriptionIcon />
          </Grid>
          <Grid item xs>
            <span className={classes.title}>{fileTitle}</span>
          </Grid>
        </Grid>
      </Grid>
      {handleDelete && (
        <Grid xs={1} item>
          <Grid container justify="flex-end">
            <Grid item zeroMinWidth>
              <IconButton
                aria-label="削除"
                color="primary"
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
})
