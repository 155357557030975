import { Storage } from 'aws-amplify'
import { v4 } from 'uuid'
import { FileObject } from 'common/Type'

type a = {
  key: string
}

export const s3FileUpload = async (
  companyId: string,
  folder: string,
  file: FileObject
) => {
  console.log('s3FileUpload')
  const extension = file.filename.split('.')[1]
  const key = `${folder}/${v4()}.${extension}`
  const result = await Storage.put(key, file.body, {
    level: 'protected',
    identityId: companyId,
    contentType: file.mimeType
  })
  console.log(JSON.stringify(result))
  return result
}

export const s3FileDownload = async (
  companyId: string,
  key: string,
  filename: string
) => {
  const result = await s3FileGet(companyId, key)
  downloadBlob(result as string, filename)
}

export const s3FileGet = async (companyId: string, key: string) => {
  console.log('s3FileDownload')
  const result = await Storage.get(key, {
    level: 'protected',
    identityId: companyId,
    expires: 60
  })
  console.log(JSON.stringify(result))
  return result
}

export function downloadBlob(url: string, filename: string) {
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}
