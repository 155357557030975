import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'
import { SecondaryButton } from 'views/components/atoms/SecondaryButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { PrimaryButton } from '../atoms/PrimaryButton'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    height: '68px',
    alignItems: 'center'
  },
  left: {
    flex: 1,
    display: 'flex',
    marginLeft: '24px'
  },
  text: {
    fontFamily: 'Hiragino Sans W3',
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.subFontColor.main,
    textAlign: 'end'
  },
  center: {
    textAlign: 'end'
  },
  right: {
    marginLeft: '16px',
    marginRight: '32px'
  },
  primaryButton: {
    color: theme.palette.primaryButtonColor.main,
    backgroundColor: theme.palette.primaryButtonBgColor.main,
    '&:hover': {
      backgroundColor: theme.palette.primaryButtonHoverColor.main
    }
  },
  secondaryButton: {
    color: theme.palette.primaryButtonColor.main,
    backgroundColor: theme.palette.primaryButtonBgColor.main,
    '&:hover': {
      backgroundColor: theme.palette.primaryButtonHoverColor.main
    }
  }
}))

type Props = {
  text?: string
  isLeftDelete?: boolean
  handleDelete?: React.MouseEventHandler<HTMLButtonElement>
  handleCancel?: React.MouseEventHandler<HTMLButtonElement>
}

export function ButtonFooter({
  text,
  isLeftDelete = false,
  handleDelete,
  handleCancel
}: Props) {
  const classes = useStyles()

  return (
    <Grid container xs={12} className={classes.root}>
      <Grid item xs={3} className={classes.left}>
        {isLeftDelete && handleDelete && (
          <SecondaryButton
            text={'削除'}
            handleClick={handleDelete}
            startIcon={<DeleteIcon />}
          />
        )}
      </Grid>
      <Grid item xs={6} className={classes.text}>
        {text}
      </Grid>
      <Grid item xs={2} className={classes.center}>
        {handleCancel && (
          <SecondaryButton text={'キャンセル'} handleClick={handleCancel} />
        )}
        {!isLeftDelete && handleDelete && (
          <SecondaryButton
            text={'削除'}
            handleClick={handleDelete}
            startIcon={<DeleteIcon />}
          />
        )}
      </Grid>
      <Grid item xs={1} className={classes.right}>
        <PrimaryButton text={'保存'} type="submit" />
      </Grid>
    </Grid>
  )
}
