import React, { EventHandler } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5)
  }
}))

type Props = {
  label: string
  onDelete: (event: EventHandler<any>) => void
}

export const CommonChip = React.memo(({ label, onDelete }: Props) => {
  const classes = useStyles()
  return (
    <>
      {label && label !== '' && (
        <li>
          <Chip className={classes.chip} label={label} onDelete={onDelete} />
        </li>
      )}
    </>
  )
})
