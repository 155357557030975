import React, { useState } from 'react'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import { OrderBy, TableHeadColumn } from 'common/Type'
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import { SortDirection } from '@material-ui/core/TableCell/TableCell'

const useStyles = makeStyles({
  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
})

type Props = {
  orderByParam?: OrderBy
  tableHeadColumnList: TableHeadColumn[]
  handleChangeSort: (orderBy: OrderBy) => void
}

export const TableHeaderWithSort = React.memo(
  ({ orderByParam, tableHeadColumnList, handleChangeSort }: Props) => {
    const classes = useStyles()
    const [order, setOrder] = useState<SortDirection>(
      orderByParam ? orderByParam.sort : false
    )
    const [orderBy, setOrderBy] = useState(
      orderByParam && orderByParam.column ? orderByParam.column : ''
    )

    const handleSort = (columnId: string) => {
      const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
      setOrder(sort)
      setOrderBy(columnId)
      handleChangeSort({ column: columnId, sort: sort })
    }

    return (
      <TableHead>
        <TableRow>
          {tableHeadColumnList.map((column) => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                width: column.width
              }}
              sortDirection={
                column.hasSort && orderBy === column.id ? order : false
              }
            >
              {column.hasSort ? (
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={
                    orderBy === column.id && order === 'desc' ? 'desc' : 'asc'
                  }
                  className={classes.tableSort}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{column.label}</>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
)
