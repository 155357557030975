import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import EventNoteIcon from '@material-ui/icons/EventNote'
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded'
import SettingsIcon from '@material-ui/icons/Settings'
import { ListItemButton } from 'views/components/atoms/ListItemButton'
import { SearchConditionContext } from 'contexts/SearchConditionContext'

const useStyles = makeStyles({
  '@global': {
    '.MuiListItem-gutters': {
      height: '44px'
    }
  }
})

export function Menu() {
  const classes = useStyles()
  const history = useHistory()
  const { searchConditionDispatch } = useContext(SearchConditionContext)
  const [open, setOpen] = useState('')

  function handleOpen(str: string) {
    if (str === open) {
      setOpen('')
    } else {
      setOpen(str)
    }
  }

  function handleClickMenu(path: string) {
    searchConditionDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      searchCondition: {}
    })
    history.push(path)
  }

  return (
    <>
      <ListItemButton
        title={'お知らせ管理'}
        isNest={false}
        icon={<EventNoteIcon />}
        onClick={() => handleOpen('notice')}
      />
      <Collapse in={open === 'notice'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            title={'管理者向け'}
            isNest={true}
            onClick={() => handleClickMenu('/notice/company/list')}
          />
          <ListItemButton
            title={'入居者向け'}
            isNest={true}
            onClick={() => handleClickMenu('/notice/customer/list')}
          />
        </List>
      </Collapse>
      <ListItemButton
        title={'運用管理'}
        isNest={false}
        icon={<HomeWorkRoundedIcon />}
        onClick={() => handleOpen('management')}
      />
      <Collapse in={open === 'management'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            title={'管理会社リスト'}
            isNest={true}
            onClick={() => handleClickMenu('/management/company/list')}
          />
          <ListItemButton
            title={'データ登録'}
            isNest={true}
            onClick={() => handleClickMenu('/management/data')}
          />
        </List>
      </Collapse>
      <ListItemButton
        title={'設定'}
        isNest={false}
        icon={<SettingsIcon />}
        onClick={() => handleOpen('setting')}
      />
      <Collapse in={open === 'setting'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            title={'利用者管理'}
            isNest={true}
            onClick={() => handleClickMenu('/setting/user/list')}
          />
        </List>
      </Collapse>
    </>
  )
}
