import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import { SelectItem } from 'common/Type'

const useStyle = makeStyles((theme) => ({
  label: {
    fontSize: '18px'
  },
  select: {
    fontWeight: 'bold'
  }
}))

type Props = {
  name: string
  label: string
  defaultValue: string
  value: string
  setValue: Dispatch<SetStateAction<string>>
  onChange?: (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => Promise<void>
  items: SelectItem[]
  readOnly?: boolean
  disabled?: boolean
  required?: boolean
  hasError?: boolean
  helperText?: string
  hiddenLabel?: boolean
}

export const Dropdown = React.memo(
  ({
    name,
    label,
    defaultValue,
    value,
    setValue,
    onChange,
    items,
    readOnly = false,
    disabled = false,
    required = false,
    hasError = false,
    helperText,
    hiddenLabel
  }: Props) => {
    const classes = useStyle()

    return (
      <FormControl
        variant="filled"
        fullWidth
        required={required}
        error={hasError}
        disabled={disabled}
        hiddenLabel={hiddenLabel}
      >
        <InputLabel htmlFor={`${name}-select`} className={classes.label}>
          {label}
        </InputLabel>
        <Select
          variant="filled"
          className={classes.select}
          defaultValue={defaultValue}
          value={value}
          onChange={(event) => {
            setValue(event.target.value as string)
            if (onChange !== undefined) {
              onChange(event)
            }
          }}
          inputProps={{
            name: name,
            id: `${name}-select`,
            readOnly: readOnly
          }}
        >
          {items &&
            items.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
)
