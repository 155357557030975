import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftBody: {
    flex: 1,
    marginTop: '32px',
    marginLeft: '32px',
    marginBottom: '32px',
    marginRight: '32px'
  },
  leftFooter: {
    backgroundColor: '#fff'
  }
})

type Props = {
  children: ReactNode
  footer?: ReactNode
}

export function Left({ children, footer }: Props) {
  const classes = useStyles()

  return (
    <div className={classes.left}>
      <div className={classes.leftBody}>{children}</div>
      <div className={classes.leftFooter}>{footer && footer}</div>
    </div>
  )
}
