import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Menu from '@material-ui/core/Menu'
import { Grid } from '@material-ui/core'
import { PrimaryButton } from '../atoms/PrimaryButton'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '12px',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  searchContent: {
    padding: '15px 20px',
    marginBottom: '-6px'
  }
}))

type Props = {
  searchWord: string
  setSearchWord: Dispatch<SetStateAction<string>>
  searchPlaceholder: string
  search: () => void
  validateSearch?: () => boolean
  searchDetailed?: ReactNode
}

export function SearchTextField({
  searchWord,
  setSearchWord,
  searchPlaceholder,
  search,
  validateSearch,
  searchDetailed
}: Props) {
  const classes = useStyles()
  const anchorRef = useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)

  function detailSearch() {
    if (validateSearch && validateSearch()) {
      return
    }
    setIsOpen(!isOpen)
    search()
  }

  return (
    <Paper className={classes.root}>
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={search}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={searchPlaceholder}
        value={searchWord}
        onChange={(event) => {
          setSearchWord(event.target.value)
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            search()
          }
        }}
      />
      {searchDetailed && (
        <>
          <IconButton
            className={classes.iconButton}
            aria-label="directions"
            onClick={() => setIsOpen(!isOpen)}
            ref={anchorRef}
          >
            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <Menu
            anchorEl={anchorRef.current}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            PaperProps={{
              style: {
                width: 500
              }
            }}
            keepMounted
            open={isOpen}
            onClose={() => setIsOpen(!isOpen)}
          >
            <Paper className={classes.searchContent}>
              <>
                <Grid container xs={12} spacing={3}>
                  {searchDetailed}
                  <Grid item xs={10} />
                  <Grid item xs={2}>
                    <PrimaryButton
                      text={'検索'}
                      type="button"
                      handleClick={detailSearch}
                    />
                  </Grid>
                </Grid>
              </>
            </Paper>
          </Menu>
        </>
      )}
    </Paper>
  )
}
