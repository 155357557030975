import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Typography from '@material-ui/core/Typography'
import { Circle } from 'views/components/atoms/Circle'
import { range } from 'utils/number.utils'
import { CircleWithNumber } from 'views/components/molecules/CircleWithNumber'
import { Loading } from 'views/components/atoms/Loaging'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '20px',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}))

type a = {
  pageNum: number
  rowsPerPage: number
  count: number
}

function PaginationCaption({ pageNum, rowsPerPage, count }: a) {
  const start = (pageNum - 1) * rowsPerPage + 1
  const end = pageNum * rowsPerPage <= count ? pageNum * rowsPerPage : count
  return (
    <Typography variant="subtitle1" style={{ marginRight: 20 }}>
      {`${start}-${end}/${count}`}
    </Typography>
  )
}

type b = {
  pageNum: number
  maxPageNumber: number
}

function createPaginationNumbers({ pageNum, maxPageNumber }: b) {
  if (maxPageNumber <= 5) return range(1, maxPageNumber)

  if (pageNum >= maxPageNumber - 2)
    return range(maxPageNumber - 4, maxPageNumber)

  if (pageNum <= 3) return range(1, 5)

  return range(pageNum - 2, pageNum + 2)
}

type Props = {
  isLoading: boolean
  handlePageNumber: (pageNum: number) => void
  pageNumber: number
  rowsPerPage: number
  count: number
}

export function Pagination({
  isLoading,
  handlePageNumber,
  pageNumber,
  rowsPerPage,
  count
}: Props) {
  const classes = useStyles()
  const maxPageNumber = Math.ceil(count / rowsPerPage)
  const pageNum = Number(pageNumber)

  const paginationNumbers = createPaginationNumbers({
    pageNum,
    maxPageNumber
  })

  const handlePrevDisabled = () => pageNum === 1
  const handleNextDisabled = () => pageNum === maxPageNumber

  function handleNextUpdate(pageNum: number, maxPageNumber: number) {
    if (pageNum < maxPageNumber) {
      handlePageNumber(pageNum + 1)
    }
  }

  function handlePrevUpdate(pageNum: number) {
    if (pageNum > 1) {
      handlePageNumber(pageNum - 1)
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} marginTop={40} />
      ) : (
        <div className={classes.root}>
          <PaginationCaption
            count={count}
            rowsPerPage={rowsPerPage}
            pageNum={pageNum}
          />
          <Circle
            disabled={handlePrevDisabled()}
            onClick={() => handlePrevUpdate(pageNum)}
          >
            <KeyboardArrowLeftIcon
              style={{ color: handlePrevDisabled() ? '#eee' : '#000' }}
            />
          </Circle>
          {paginationNumbers.map((item) => (
            <CircleWithNumber
              pageNum={pageNum}
              paginationNumber={item}
              handlePageNumber={() => handlePageNumber(item)}
            />
          ))}
          <Circle
            disabled={handleNextDisabled()}
            onClick={() => handleNextUpdate(pageNum, maxPageNumber)}
          >
            <KeyboardArrowRight
              style={{ color: handleNextDisabled() ? '#eee' : '#000' }}
            />
          </Circle>
        </div>
      )}
    </>
  )
}
