import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Header } from 'views/components/organisms/Header'
import { Menu } from 'views/components/organisms/Menu'

const useStyles = makeStyles((theme) => ({
  wrapperBody: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    flex: 1,
    display: 'flex',
    paddingTop: '64px',
    backgroundColor: theme.palette.gray.main
  },
  menu: {
    boxSizing: 'border-box',
    position: 'fixed',
    width: '256px',
    height: '100%',
    overflow: 'auto',
    paddingTop: '8px',
    paddingBottom: '64px',
    backgroundColor: theme.palette.white.main
  },
  contentWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: '256px'
  },
  content: {
    flex: 1,
    overflow: 'scroll'
  }
}))

type Props = {
  title: string
  backEnabled?: boolean
  headerComponent?: ReactNode
  children?: ReactNode
}

export function Layout({
  title,
  backEnabled = false,
  headerComponent,
  children
}: Props) {
  const classes = useStyles()

  return (
    <>
      <CssBaseline />
      <div className={classes.wrapperBody}>
        <Header
          title={title}
          backEnabled={backEnabled}
          headerComponent={headerComponent}
        />
        <div className={classes.body}>
          <div className={classes.menu}>
            <Menu />
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.content}>{children && children}</div>
          </div>
        </div>
      </div>
    </>
  )
}
