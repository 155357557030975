import React, {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  FormEvent
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, TableCell, TableRow } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useSnackbar } from 'notistack'
import { Layout } from 'views/components/template/Layout'
import { ListWrapper } from 'views/components/template/ListWrapper'
import { Pagination } from 'views/components/organisms/Pagination'
import { ListBody } from 'views/components/template/ListBody'
import { TablePaginationWrapper } from 'views/components/molecules/TablePaginationWrapper'
import { TableList } from 'views/components/organisms/TableList'
import { Label } from 'views/components/atoms/Label'
import { FormLayout } from 'views/components/organisms/FormLayout'
import { Dropdown } from 'views/components/atoms/Dropdown'
import { PrimaryButton } from 'views/components/atoms/PrimaryButton'
import { DateLabel } from 'views/components/atoms/DateLabel'
import { LoadingContext } from 'contexts/LoadingContext'
import { FileForm } from 'views/components/molecules/FileForm'
import { s3FileDownload, s3FileUpload } from 'utils/aws.utils'
import {
  getCompanyList,
  getDataLinkHistoryList,
  getFaqList,
  getMessageTemplateList
} from 'graphql/queries'
import { createDataLinkHistory } from 'graphql/mutations'
import { CallGraphQL } from 'utils/api.utils'
import {
  Attachment,
  DataLinkHistoryAndAttachment,
  FileObject,
  SelectItem,
  TableHeadColumn,
  UploadFileResponse,
  Validate
} from 'common/Type'
import {
  CreateAttachmentInput,
  CreateDataLinkHistoryMutation,
  CreateDataLinkHistoryMutationVariables,
  GetCompanyListQuery,
  GetCompanyListQueryVariables,
  GetDataLinkHistoryListQuery,
  GetDataLinkHistoryListQueryVariables,
  GetFaqListQuery,
  GetFaqListQueryVariables,
  GetMessageTemplateListQuery,
  GetMessageTemplateListQueryVariables
} from 'API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { Loading } from 'views/components/atoms/Loaging'

const useStyles = makeStyles((theme) => ({
  fileForm: {
    marginBottom: '20px'
  },
  registerButton: {
    textAlign: 'end'
  },
  tableWrapper: {
    height: '52px',
    minHeight: '52px'
  }
}))

const PAGE_LIMIT = 15

type DataSettingValidate = {
  companyId?: Validate
  csvTypeId?: Validate
  file?: Validate
}

const CsvTypeSelectItems: SelectItem[] = [
  { value: '7', label: 'FAQ' },
  { value: '8', label: '定型文' }
]

const headColumn: TableHeadColumn[] = [
  { id: 'status', label: '状態', minWidth: 150, width: 150, hasSort: false },
  { id: 'name', label: '管理会社', hasSort: false },
  { id: 'type', label: '種別', minWidth: 150, width: 150, hasSort: false },
  { id: 'filename', label: 'アップロードファイル', hasSort: false },
  {
    id: 'createdAt',
    label: '登録日時',
    minWidth: 150,
    width: 150,
    hasSort: false
  }
]

function CellWithDataLinkState(dataLinkStateId: number) {
  let color = 'gray'
  let label = '不明'
  switch (dataLinkStateId) {
    case 1:
      color = 'blue'
      label = '処理待ち'
      break
    case 2:
      color = 'blue'
      label = '処理中'
      break
    case 3:
      color = 'gray'
      label = '完了'
      break
    case 4:
      color = 'red'
      label = '失敗'
      break
  }
  return <Label color={color} text={label} />
}

function CellWithCompany(companyId: number, companySelectItem: SelectItem[]) {
  for (let i of companySelectItem) {
    if (String(companyId) === i.value) {
      return <>{i.label}</>
    }
  }
  return <></>
}

function CellWithDataType(dataLinkTypeId: number, dataTypeId: number | null) {
  switch (dataLinkTypeId) {
    case 1:
      return <>API</>
    case 2:
      switch (dataTypeId) {
        case 1:
          return <>顧客</>
        case 2:
          return <>建物</>
        case 3:
          return <>部屋</>
        case 4:
          return <>契約</>
        case 5:
          return <>契約（月額賃料）</>
        default:
          return <></>
      }
    case 3:
      return <>システム</>
    case 4:
      return <>掲示板</>
    case 5:
      return <>チャット</>
    case 6:
      return <>申請</>
    case 7:
      return <>FAQ</>
    case 8:
      return <>定型文</>
    default:
      return <></>
  }
}

export function DataManagementList() {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { loadingDispatch } = useContext(LoadingContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [validate, setValidate] = useState<DataSettingValidate>()
  const [companyId, setCompanyId] = useState('')
  const [csvTypeId, setCsvTypeId] = useState('')
  const [files, setFiles] = useState<FileObject[]>([])
  const [companySelectItemList, setCompanySelectItemList] = useState<
    SelectItem[]
  >([])
  const [dataLinkHistoryList, setDataLinkHistoryList] =
    useState<DataLinkHistoryAndAttachment[]>()
  const [count, setCount] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [faqCount, setFaqCount] = useState<number>(0)
  const [messageTemplateCount, setMessageTemplateCount] = useState<number>(0)
  const [isCountLoading, setIsCountLoading] = useState<boolean>(false)

  const handleAddFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const obj: FileObject = {
          mimeType: file.type,
          filename: file.name,
          body: reader.result,
          file: file
        }
        setFiles([obj])
      }
      reader.readAsText(file)
    }
  }

  const handleDelete = () => {
    setFiles([])
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: true
    })
    if (validateForm()) {
      loadingDispatch({
        type: 'SET', // reducerで指定したtypeを使う
        isLoading: false
      })
      enqueueSnackbar('入力内容に誤りがあります', {
        variant: 'warning'
      })
      return
    }

    let key = 'csvs'
    let dataLinkTypeId = 0
    let dataTypeId: number | null = null
    switch (csvTypeId) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
        dataLinkTypeId = 2
        dataTypeId = Number(csvTypeId)
        break
      case '7':
        dataLinkTypeId = Number(csvTypeId)
        key += '/faq/data'
        break
      case '8':
        dataLinkTypeId = Number(csvTypeId)
        key += '/message-template/data'
        break
      default:
        loadingDispatch({
          type: 'SET', // reducerで指定したtypeを使う
          isLoading: false
        })
        enqueueSnackbar('入力内容に誤りがあります', {
          variant: 'warning'
        })
        return
    }

    const uploadResult = await s3FileUpload(companyId, key, files[0])
    const uploadFileResponse = uploadResult as UploadFileResponse
    const attachment: CreateAttachmentInput = {
      status: 1,
      company_id: Number(companyId),
      attachment_type_id: 4,
      mime_type: files[0].mimeType,
      filename: files[0].filename,
      key: uploadFileResponse.key
    }

    const param: CreateDataLinkHistoryMutationVariables = {
      input: {
        company_id: Number(companyId),
        data_link_type_id: dataLinkTypeId,
        data_link_state_id: 1,
        attachment: attachment
      }
    }
    if (dataTypeId) {
      param.input.data_type_id = dataTypeId
    }

    const response = await callCreateDataLinkHistory(param)
    if (response.data && response.data.createDataLinkHistory) {
      enqueueSnackbar('保存しました', {
        variant: 'success'
      })
    } else {
      enqueueSnackbar('保存に失敗しました', {
        variant: 'error'
      })
    }

    setCompanyId('')
    setCsvTypeId('')
    setFiles([])
    fetchData()
    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: false
    })
  }

  function validateForm() {
    const valid: DataSettingValidate = {}
    if (!companyId || companyId === '') {
      valid.companyId = { isError: true, message: '管理会社は必須です' }
    }
    if (!csvTypeId || csvTypeId === '') {
      valid.csvTypeId = { isError: true, message: '種別は必須です' }
    }
    if (!files || files.length === 0) {
      valid.file = { isError: true, message: 'ファイルは必須です' }
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  async function callCreateDataLinkHistory(
    params: CreateDataLinkHistoryMutationVariables
  ): Promise<GraphQLResult<CreateDataLinkHistoryMutation>> {
    return await CallGraphQL(createDataLinkHistory, params, false)
  }

  const handleReload = () => {
    fetchData()
    if (companyId) {
      fetchCounts(Number(companyId))
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage + 1)
  }
  const handleCustomChangePage = (newPage: number) => {
    setPageNumber(newPage)
  }

  useEffect(() => {
    fetchData()
  }, [pageNumber])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([
      dataLinkHistoryList ? null : fetchCompanyList(),
      fetchDataLinkHistoryListList()
    ])
    setIsLoading(false)
  }

  async function fetchCompanyList() {
    const params: GetCompanyListQueryVariables = {
      filter: {},
      offset: 0,
      limit: 0
    }
    const getCompanyListResult = await callGetCompanyList(params)
    if (getCompanyListResult.data && getCompanyListResult.data.getCompanyList) {
      const getCompanyListResultData = getCompanyListResult.data.getCompanyList
      if (getCompanyListResultData.data) {
        const companySelectItems: SelectItem[] = []
        for (let i of getCompanyListResultData.data) {
          if (i?.company?.name && i?.company?.id) {
            companySelectItems.push({
              label: i.company.name,
              value: String(i.company.id)
            })
          }
        }
        setCompanySelectItemList(companySelectItems)
      }
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callGetCompanyList(
    params: GetCompanyListQueryVariables
  ): Promise<GraphQLResult<GetCompanyListQuery>> {
    return await CallGraphQL(getCompanyList, params, false)
  }

  async function fetchDataLinkHistoryListList() {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0

    const params: GetDataLinkHistoryListQueryVariables = {
      filter: { data_link_type_id: { contains: [7, 8] } },
      offset: offset,
      limit: PAGE_LIMIT
    }
    const getDataLinkHistoryListResult = await callGetDataLinkHistoryListList(
      params
    )
    if (
      getDataLinkHistoryListResult.data &&
      getDataLinkHistoryListResult.data.getDataLinkHistoryList
    ) {
      const getDataLinkHistoryListResultData =
        getDataLinkHistoryListResult.data.getDataLinkHistoryList
      if (getDataLinkHistoryListResultData.data) {
        setDataLinkHistoryList(
          getDataLinkHistoryListResultData.data as DataLinkHistoryAndAttachment[]
        )
      }
      if (getDataLinkHistoryListResultData.paging) {
        setCount(getDataLinkHistoryListResultData.paging.count)
      }
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callGetDataLinkHistoryListList(
    params: GetDataLinkHistoryListQueryVariables
  ): Promise<GraphQLResult<GetDataLinkHistoryListQuery>> {
    return await CallGraphQL(getDataLinkHistoryList, params, false)
  }

  const handleChangeCompany = async (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    await fetchCounts(event.target.value as number)
  }

  async function fetchCounts(companyId: number) {
    setIsCountLoading(true)
    await Promise.all([
      fetchFaqListByCompanyId(companyId),
      fetchMessageTemplateListByCompanyId(companyId)
    ])
    setIsCountLoading(false)
  }

  async function fetchFaqListByCompanyId(companyId: number) {
    const params: GetFaqListQueryVariables = {
      filter: {},
      companyId: companyId,
      offset: 0,
      limit: 1
    }
    const getFaqListResult = await callGetFaqList(params)
    if (getFaqListResult?.data?.getFaqList?.paging) {
      setFaqCount(getFaqListResult.data.getFaqList.paging.count)
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callGetFaqList(
    params: GetFaqListQueryVariables
  ): Promise<GraphQLResult<GetFaqListQuery>> {
    return await CallGraphQL(getFaqList, params, false)
  }

  async function fetchMessageTemplateListByCompanyId(companyId: number) {
    const params: GetMessageTemplateListQueryVariables = {
      filter: {},
      companyId: companyId,
      offset: 0,
      limit: 1
    }
    const getMessageTemplateListResult = await callGetMessageTemplateList(
      params
    )
    if (getMessageTemplateListResult?.data?.getMessageTemplateList?.paging) {
      setMessageTemplateCount(
        getMessageTemplateListResult.data.getMessageTemplateList.paging.count
      )
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callGetMessageTemplateList(
    params: GetMessageTemplateListQueryVariables
  ): Promise<GraphQLResult<GetMessageTemplateListQuery>> {
    return await CallGraphQL(getMessageTemplateList, params, false)
  }

  const handleClickGetErrorFile = async (
    attachment: Attachment | null,
    event: React.MouseEvent<HTMLTableRowElement>
  ) => {
    event.preventDefault()
    if (
      attachment &&
      attachment?.company_id &&
      attachment?.key &&
      attachment?.filename
    ) {
      await s3FileDownload(
        String(attachment.company_id),
        attachment.key,
        attachment.filename
      )
    }
  }

  return (
    <Layout title={'利用者管理'}>
      <ListWrapper>
        <form noValidate onSubmit={(e) => handleSubmit(e)}>
          <Grid container xs={12} className={classes.fileForm}>
            <FormLayout
              title={
                'ファイルをアップロード ※文字コードはUTF-8のみ対応してます'
              }
              footer={
                <Grid item xs={12} className={classes.registerButton}>
                  <PrimaryButton text={'登録'} type={'submit'} />
                </Grid>
              }
            >
              <Grid container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Dropdown
                    name={'company'}
                    label={'管理会社'}
                    defaultValue={companyId}
                    value={companyId}
                    setValue={setCompanyId}
                    onChange={handleChangeCompany}
                    items={companySelectItemList}
                    required
                    hasError={validate?.companyId?.isError}
                    helperText={validate?.companyId?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    name={'type'}
                    label={'種別'}
                    defaultValue={csvTypeId}
                    value={csvTypeId}
                    setValue={setCsvTypeId}
                    items={CsvTypeSelectItems}
                    required
                    hasError={validate?.csvTypeId?.isError}
                    helperText={validate?.csvTypeId?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FileForm
                    text={'ファイルを追加*'}
                    accept={'text/csv'}
                    files={files}
                    setFiles={setFiles}
                    maxFileLength={1}
                    onClick={handleAddFile}
                    startIcon={<AddIcon />}
                    hasError={validate?.file?.isError}
                    helperText={validate?.file?.message}
                    handleDelete={handleDelete}
                  />
                </Grid>
              </Grid>
            </FormLayout>
          </Grid>
          <Grid
            container
            alignItems={'center'}
            xs={12}
            className={classes.tableWrapper}
          >
            <Grid item xs={3}>
              <PrimaryButton
                text={'更新'}
                type={'button'}
                handleClick={handleReload}
              />
            </Grid>
            <Grid item xs={6}>
              {isCountLoading ? (
                <Loading isLoading={isCountLoading} />
              ) : (
                <>
                  {companyId && (
                    <>
                      FAQ件数: {faqCount} 定型文件数: {messageTemplateCount}
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={3}>
              <TablePaginationWrapper
                isLoading={isLoading}
                count={count}
                rowsPerPage={PAGE_LIMIT}
                page={pageNumber - 1}
                onChangePage={handleChangePage}
              />
            </Grid>
          </Grid>
          <ListBody isLoading={isLoading}>
            <TableList
              tableHeadColumnList={headColumn}
              handlePageNumber={handleCustomChangePage}
            >
              {dataLinkHistoryList &&
                dataLinkHistoryList.length > 0 &&
                dataLinkHistoryList.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.data_link_history?.id}
                      onClick={(e) =>
                        handleClickGetErrorFile(row.error_attachment, e)
                      }
                    >
                      <TableCell>
                        {row.data_link_history &&
                          CellWithDataLinkState(
                            row.data_link_history.data_link_state_id
                          )}
                      </TableCell>
                      <TableCell>
                        {row.data_link_history &&
                          CellWithCompany(
                            row.data_link_history.company_id,
                            companySelectItemList
                          )}
                      </TableCell>
                      <TableCell>
                        {row.data_link_history &&
                          CellWithDataType(
                            row.data_link_history.data_link_type_id,
                            row.data_link_history.data_type_id
                          )}
                      </TableCell>
                      <TableCell>{row.attachment?.filename}</TableCell>
                      <TableCell>
                        {row.data_link_history && (
                          <DateLabel
                            timestamp={row.data_link_history.created_at}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableList>
          </ListBody>
          <Pagination
            isLoading={isLoading}
            handlePageNumber={handleCustomChangePage}
            pageNumber={pageNumber}
            count={count}
            rowsPerPage={PAGE_LIMIT}
          />
        </form>
      </ListWrapper>
    </Layout>
  )
}
