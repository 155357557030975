import React, { useReducer, createContext, ReactNode } from 'react'
import { State, Action, loadingReducer } from 'reducers/LoadingReducer'

type ContextType = {
  loadingState: State
  loadingDispatch: React.Dispatch<Action>
}

export const LoadingContext = createContext({} as ContextType)

type Props = {
  children: ReactNode
}

const initialState = { isLoading: false }

export function LoadingProvider({ children }: Props) {
  const [loadingState, loadingDispatch] = useReducer(
    loadingReducer,
    initialState
  )

  return (
    <LoadingContext.Provider value={{ loadingState, loadingDispatch }}>
      {children}
    </LoadingContext.Provider>
  )
}
