import React, { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Grid, TextField, Divider } from '@material-ui/core'
import {
  CompanyNoticeAndCreatedUserAndUpdatedUserDeletedUser,
  Validate
} from 'common/Type'
import { LoadingContext } from 'contexts/LoadingContext'
import { Layout } from 'views/components/template/Layout'
import { LeftAndRightWrapper } from 'views/components/organisms/LeftAndRightWrapper'
import { Left } from 'views/components/molecules/Left'
import { Right } from 'views/components/molecules/Right'
import { FormLayout } from 'views/components/organisms/FormLayout'
import { Column } from 'views/components/molecules/Column'
import { DateLabel } from 'views/components/atoms/DateLabel'
import { Name } from 'views/components/atoms/Name'
import { TextareaAutosizeItem } from 'views/components/atoms/TextareaAutosizeItem'
import { ButtonFooter } from 'views/components/organisms/ButtonFooter'
import { Loading } from 'views/components/atoms/Loaging'
import {
  CreateCompanyNoticeMutation,
  CreateCompanyNoticeMutationVariables,
  GetCompanyNoticeQuery,
  GetCompanyNoticeQueryVariables,
  TableCompanyNoticeFilterInput,
  UpdateCompanyNoticeMutation,
  UpdateCompanyNoticeMutationVariables
} from 'API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { getCompanyNotice } from 'graphql/queries'
import { CallGraphQL } from 'utils/api.utils'
import { createCompanyNotice, updateCompanyNotice } from 'graphql/mutations'

type UseParamTypes = {
  id?: string
}

type CompanyNoticeValidate = {
  body?: Validate
}

export function CompanyNoticeEdit() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { loadingDispatch } = useContext(LoadingContext)
  const { id } = useParams<UseParamTypes>()
  const [validate, setValidate] = useState<CompanyNoticeValidate>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [body, setBody] = useState('')
  const [url, setUrl] = useState('')
  const [
    companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser,
    setCompanyNoticeAndCreatedUserAndUpdatedUserDeletedUser
  ] = useState<CompanyNoticeAndCreatedUserAndUpdatedUserDeletedUser>()
  const title = id
    ? '更新 | 管理者向けお知らせ管理'
    : '新規作成 | 管理者向けお知らせ管理'

  function handleCancel() {
    if (
      companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser &&
      companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.companyNotice
    ) {
      setBody(
        companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.companyNotice
          .body
          ? companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
              .companyNotice.body
          : ''
      )
      setUrl(
        companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.companyNotice
          .url
          ? companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
              .companyNotice.url
          : ''
      )
    } else {
      setBody('')
      setUrl('')
    }
  }

  async function handleDelete() {
    if (!id) {
      console.log('idが見つかりません')
      return
    }
    if (!window.confirm(`削除します。よろしいですか？`)) {
      return
    }
    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: true
    })
    const params: UpdateCompanyNoticeMutationVariables = {
      input: {
        id: id,
        status: 3,
        body: companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
          ?.companyNotice?.body
          ? companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
              ?.companyNotice?.body
          : body,
        url: companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
          ?.companyNotice?.url
          ? companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
              ?.companyNotice?.url
          : url
      }
    }
    const response = await callUpdateCompanyNotice(params)
    if (response?.data?.updateCompanyNotice) {
      enqueueSnackbar('削除しました', {
        variant: 'success'
      })
    } else {
      enqueueSnackbar('削除に失敗しました', {
        variant: 'error'
      })
    }
    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: false
    })
    history.push(`/notice/company/list`)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([id ? fetchCompanyNotice() : null])
    setIsLoading(false)
  }

  async function fetchCompanyNotice() {
    const tableCompanyNoticeFilterInput: TableCompanyNoticeFilterInput = {
      id: { eq: Number(id) }
    }
    const params: GetCompanyNoticeQueryVariables = {
      filter: tableCompanyNoticeFilterInput
    }
    const getCompanyNoticeResult = await callGetCompanyNotice(params)
    if (
      getCompanyNoticeResult.data &&
      getCompanyNoticeResult.data.getCompanyNotice
    ) {
      const getCompanyNoticeResultData =
        getCompanyNoticeResult.data.getCompanyNotice
      setCompanyNoticeAndCreatedUserAndUpdatedUserDeletedUser(
        getCompanyNoticeResultData as CompanyNoticeAndCreatedUserAndUpdatedUserDeletedUser
      )
      if (getCompanyNoticeResultData.companyNotice) {
        setBody(
          getCompanyNoticeResultData.companyNotice.body
            ? getCompanyNoticeResultData.companyNotice.body
            : ''
        )
        setUrl(
          getCompanyNoticeResultData.companyNotice.url
            ? getCompanyNoticeResultData.companyNotice.url
            : ''
        )
      }
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callGetCompanyNotice(
    params: GetCompanyNoticeQueryVariables
  ): Promise<GraphQLResult<GetCompanyNoticeQuery>> {
    return await CallGraphQL(getCompanyNotice, params, false)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: true
    })

    if (validateForm()) {
      loadingDispatch({
        type: 'SET', // reducerで指定したtypeを使う
        isLoading: false
      })
      enqueueSnackbar('入力内容に誤りがあります', {
        variant: 'warning'
      })
      return
    }

    if (id) {
      const params: UpdateCompanyNoticeMutationVariables = {
        input: {
          id: id,
          status: 1,
          body: body,
          url: url
        }
      }
      const response = await callUpdateCompanyNotice(params)
      if (response.data && response.data.updateCompanyNotice) {
        enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        await fetchData()
      } else {
        enqueueSnackbar('保存に失敗しました', {
          variant: 'error'
        })
      }
    } else {
      const params: CreateCompanyNoticeMutationVariables = {
        input: {
          status: 1,
          body: body,
          url: url
        }
      }
      const response = await callCreateCompanyNotice(params)
      if (response.data && response.data.createCompanyNotice) {
        enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        history.push(
          `/notice/company/edit/${response.data.createCompanyNotice.id}`
        )
      } else {
        enqueueSnackbar('保存に失敗しました', {
          variant: 'error'
        })
      }
    }
    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: false
    })
  }

  function validateForm() {
    const valid: CompanyNoticeValidate = {}
    if (!body || body === '') {
      valid.body = { isError: true, message: '本文は必須です' }
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  async function callUpdateCompanyNotice(
    params: UpdateCompanyNoticeMutationVariables
  ): Promise<GraphQLResult<UpdateCompanyNoticeMutation>> {
    return await CallGraphQL(updateCompanyNotice, params, false)
  }

  async function callCreateCompanyNotice(
    params: CreateCompanyNoticeMutationVariables
  ): Promise<GraphQLResult<CreateCompanyNoticeMutation>> {
    return await CallGraphQL(createCompanyNotice, params, false)
  }

  return (
    <form noValidate onSubmit={(e) => handleSubmit(e)}>
      <Layout title={title} backEnabled={true}>
        <LeftAndRightWrapper>
          <Left
            footer={
              <ButtonFooter
                text={'※登録すると即時反映されます'}
                handleCancel={handleCancel}
                isLeftDelete={true}
                handleDelete={id ? handleDelete : undefined}
              />
            }
          >
            <Grid container xs={12}>
              {isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                <FormLayout title={'お知らせ内容'}>
                  <Grid container xs={12} spacing={2}>
                    <TextareaAutosizeItem
                      body={body}
                      setBody={setBody}
                      rowsMin={6}
                      placeholder={'本文*'}
                      validate={validate && validate.body}
                    />
                    <Grid alignItems="center" item xs={12} spacing={1}>
                      <TextField
                        label={'関連URL'}
                        id="url"
                        fullWidth
                        autoComplete="url"
                        variant="filled"
                        value={url}
                        defaultValue={url}
                        onChange={(event) => setUrl(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </FormLayout>
              )}
            </Grid>
          </Left>
          <Right>
            {isLoading ? (
              <Loading isLoading={isLoading} marginTop={20} />
            ) : (
              <>
                {companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser && (
                  <>
                    <FormLayout title={'登録情報'}>
                      {companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.companyNotice &&
                        companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
                          .companyNotice.created_at && (
                          <Column title={'登録日時'}>
                            <DateLabel
                              timestamp={
                                companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
                                  .companyNotice.created_at
                              }
                            />
                          </Column>
                        )}
                      <Divider />
                      {companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.createdUser &&
                        companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
                          .createdUser.name && (
                          <Column title={'登録者'}>
                            <Name
                              name={
                                companyNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
                                  .createdUser.name
                              }
                            />
                          </Column>
                        )}
                    </FormLayout>
                    <Divider />
                  </>
                )}
              </>
            )}
          </Right>
        </LeftAndRightWrapper>
      </Layout>
    </form>
  )
}
