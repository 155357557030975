import React, { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Grid, TextField } from '@material-ui/core'
import { AdminUser, SelectItem, Validate } from 'common/Type'
import { LoadingContext } from 'contexts/LoadingContext'
import { Layout } from 'views/components/template/Layout'
import { LeftAndRightWrapper } from 'views/components/organisms/LeftAndRightWrapper'
import { Left } from 'views/components/molecules/Left'
import { FormLayout } from 'views/components/organisms/FormLayout'
import { ButtonFooter } from 'views/components/organisms/ButtonFooter'
import { Loading } from 'views/components/atoms/Loaging'
import {
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables,
  GetAdminUserQuery,
  GetAdminUserQueryVariables,
  TableAdminUserFilterInput,
  UpdateAdminUserMutation,
  UpdateAdminUserMutationVariables
} from 'API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { getAdminUser } from 'graphql/queries'
import { CallGraphQL } from 'utils/api.utils'
import { createAdminUser, updateAdminUser } from 'graphql/mutations'
import { PasswordText } from 'views/components/atoms/PasswordText'
import { Dropdown } from 'views/components/atoms/Dropdown'

type UseParamTypes = {
  id?: string
}

type UserEditValidate = {
  status?: Validate
  name?: Validate
  username?: Validate
  password?: Validate
  confirmPassword?: Validate
}

const items: SelectItem[] = [
  { label: '利用中', value: '1' },
  { label: '停止中', value: '2' }
]

export function UserSettingEdit() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { loadingDispatch } = useContext(LoadingContext)
  const { id } = useParams<UseParamTypes>()
  const [validate, setValidate] = useState<UserEditValidate>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [status, setStatus] = useState('')
  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [adminUser, setAdminUser] = useState<AdminUser>()
  const title = id ? '更新 | 利用者管理' : '新規作成 | 利用者管理'

  function handleCancel() {
    if (adminUser) {
      setStatus(adminUser.status ? String(adminUser.status) : '')
      setName(adminUser.name ? adminUser.name : '')
      setUsername(adminUser.username ? adminUser.username : '')
      setPassword('')
      setConfirmPassword('')
      setValidate(undefined)
    } else {
      setStatus('')
      setName('')
      setUsername('')
      setPassword('')
      setConfirmPassword('')
      setValidate(undefined)
    }
  }

  async function handleDelete() {
    if (!id) {
      console.log('idが見つかりません')
      return
    }
    if (!window.confirm(`削除します。よろしいですか？`)) {
      return
    }
    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: true
    })
    const params: UpdateAdminUserMutationVariables = {
      input: {
        id: id,
        status: 3,
        username: adminUser?.username ? adminUser?.username : username,
        password: adminUser?.password ? adminUser?.password : password,
        name: adminUser?.name ? adminUser?.name : name
      }
    }
    const response = await callUpdateAdminUser(params)
    if (response.data && response.data.updateAdminUser) {
      enqueueSnackbar('削除しました', {
        variant: 'success'
      })
    } else {
      enqueueSnackbar('削除に失敗しました', {
        variant: 'error'
      })
    }
    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: false
    })
    history.push(`/setting/user/list`)
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([id ? fetchAdminUser() : null])
    setIsLoading(false)
  }

  async function fetchAdminUser() {
    const tableAdminUserFilterInput: TableAdminUserFilterInput = {
      id: { eq: Number(id) }
    }
    const params: GetAdminUserQueryVariables = {
      filter: tableAdminUserFilterInput
    }
    const getAdminUserResult = await callGetAdminUser(params)
    if (getAdminUserResult.data && getAdminUserResult.data.getAdminUser) {
      const getAdminUserResultData = getAdminUserResult.data.getAdminUser
      setAdminUser(getAdminUserResultData as AdminUser)
      setStatus(
        getAdminUserResultData.status
          ? String(getAdminUserResultData.status)
          : ''
      )
      setName(getAdminUserResultData.name ? getAdminUserResultData.name : '')
      setUsername(
        getAdminUserResultData.username ? getAdminUserResultData.username : ''
      )
      setPassword('')
      setConfirmPassword('')
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callGetAdminUser(
    params: GetAdminUserQueryVariables
  ): Promise<GraphQLResult<GetAdminUserQuery>> {
    return await CallGraphQL(getAdminUser, params, false)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: true
    })

    if (validateForm()) {
      loadingDispatch({
        type: 'SET', // reducerで指定したtypeを使う
        isLoading: false
      })
      enqueueSnackbar('入力内容に誤りがあります', {
        variant: 'warning'
      })
      return
    }

    if (id) {
      const params: UpdateAdminUserMutationVariables = {
        input: {
          id: id,
          status: Number(status),
          username: username,
          name: name
        }
      }
      if (password) {
        params.input.password = password
      }
      const response = await callUpdateAdminUser(params)
      if (response.data && response.data.updateAdminUser) {
        enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        await fetchData()
      } else {
        enqueueSnackbar('保存に失敗しました', {
          variant: 'error'
        })
      }
    } else {
      const params: CreateAdminUserMutationVariables = {
        input: {
          status: 1,
          username: username,
          password: password,
          name: name
        }
      }
      const response = await callCreateAdminUser(params)
      if (response.data && response.data.createAdminUser) {
        enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        history.push(`/setting/user/edit/${response.data.createAdminUser.id}`)
      } else {
        enqueueSnackbar('保存に失敗しました', {
          variant: 'error'
        })
      }
    }
    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: false
    })
  }

  function validateForm() {
    const valid: UserEditValidate = {}
    if (!name || name === '') {
      valid.name = { isError: true, message: '氏名は必須です' }
    }
    if (!username || username === '') {
      valid.username = { isError: true, message: 'メールアドレスは必須です' }
    }
    if ((!id || confirmPassword) && (!password || password === '')) {
      valid.password = { isError: true, message: 'パスワードは必須です' }
    }
    if ((!id || password) && (!confirmPassword || confirmPassword === '')) {
      valid.confirmPassword = {
        isError: true,
        message: '確認用パスワードは必須です'
      }
    }

    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        valid.password = {
          isError: true,
          message: '確認用パスワードと一致しません'
        }
        valid.confirmPassword = {
          isError: true,
          message: 'パスワードと一致しません'
        }
      }
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  async function callUpdateAdminUser(
    params: UpdateAdminUserMutationVariables
  ): Promise<GraphQLResult<UpdateAdminUserMutation>> {
    return await CallGraphQL(updateAdminUser, params, false)
  }

  async function callCreateAdminUser(
    params: CreateAdminUserMutationVariables
  ): Promise<GraphQLResult<CreateAdminUserMutation>> {
    return await CallGraphQL(createAdminUser, params, false)
  }

  return (
    <form noValidate autoComplete={'off'} onSubmit={(e) => handleSubmit(e)}>
      <Layout title={title} backEnabled={true}>
        <LeftAndRightWrapper>
          <Left
            footer={
              id ? (
                <ButtonFooter
                  isLeftDelete={true}
                  handleDelete={handleDelete}
                  handleCancel={handleCancel}
                />
              ) : (
                <ButtonFooter isLeftDelete={true} handleCancel={handleCancel} />
              )
            }
          >
            <Grid container xs={12}>
              {isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                <FormLayout title={'利用者内容'}>
                  <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Dropdown
                        name={'status'}
                        label={'状態'}
                        defaultValue={id ? status : '1'}
                        value={id ? status : '1'}
                        setValue={setStatus}
                        items={items}
                        disabled={!id}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="filled"
                        margin={'none'}
                        required
                        fullWidth
                        id="name"
                        label="氏名"
                        name="name"
                        defaultValue={name}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        error={
                          validate && validate.name && validate.name.isError
                        }
                        helperText={
                          validate && validate.name && validate.name.message
                            ? validate.name.message
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete={'off'}
                        variant="filled"
                        margin={'none'}
                        required
                        fullWidth
                        id="username"
                        label="メールアドレス"
                        name="new-username"
                        defaultValue={username}
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        error={
                          validate &&
                          validate.username &&
                          validate.username.isError
                        }
                        helperText={
                          validate &&
                          validate.username &&
                          validate.username.message
                            ? validate.username.message
                            : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PasswordText
                        autoComplete={'new-password'}
                        password={password}
                        setPassword={setPassword}
                        isError={
                          validate &&
                          validate.password &&
                          validate.password.isError
                        }
                        helperText={
                          validate &&
                          validate.password &&
                          validate.password.message
                        }
                        isRequired={!id}
                        margin={'none'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PasswordText
                        label={'確認用パスワード'}
                        password={confirmPassword}
                        setPassword={setConfirmPassword}
                        isError={
                          validate &&
                          validate.confirmPassword &&
                          validate.confirmPassword.isError
                        }
                        helperText={
                          validate &&
                          validate.confirmPassword &&
                          validate.confirmPassword.message
                        }
                        isRequired={!id}
                        margin={'none'}
                      />
                    </Grid>
                  </Grid>
                </FormLayout>
              )}
            </Grid>
          </Left>
        </LeftAndRightWrapper>
      </Layout>
    </form>
  )
}
