import React, { useReducer, createContext, ReactNode } from 'react'
import { State, Action, adminUserReducer } from 'reducers/AdminUserReducer'

type ContextType = {
  adminUserState: State
  adminUserDispatch: React.Dispatch<Action>
}

export const AdminUserContext = createContext({} as ContextType)

type Props = {
  children: ReactNode
}

const initialState = { adminUser: undefined }

export function AdminUserProvider({ children }: Props) {
  const [adminUserState, adminUserDispatch] = useReducer(
    adminUserReducer,
    initialState
  )

  return (
    <AdminUserContext.Provider value={{ adminUserState, adminUserDispatch }}>
      {children}
    </AdminUserContext.Provider>
  )
}
