import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { SvgIconProps } from '@material-ui/core'

const useStyles = makeStyles<Theme, Props>((theme) => {
  return {
    icon: {
      color: theme.palette.menuIcon.main
    },
    nested: {
      paddingLeft: (props) => (props.isNest ? theme.spacing(9) : 16)
    },
    listItemText: {
      color: theme.palette.fontColor.main,
      fontSize: '14px',
      fontFamily: 'Hiragino Sans W3',
      lineHeight: '21px'
    }
  }
})

type Props = {
  title: string
  isNest: boolean
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void
  icon?: React.HTMLProps<SvgIconProps>
}

// TODO 試す export const ListItemButton = React.memo((props: Props) => {
export function ListItemButton(props: Props) {
  const classes = useStyles(props)
  const { title, icon, onClick } = props

  return (
    <ListItem button className={classes.nested} onClick={onClick}>
      {icon && <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>}
      <ListItemText className={classes.listItemText} primary={title} />
    </ListItem>
  )
}
