import React, { Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Validate } from 'common/Type'

const useStyles = makeStyles({
  body: {
    width: '100%',
    resize: 'vertical',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'Hiragino Sans W6, Hiragino Sans W3',
    fontWeight: 400,
    lineHeight: '1.4em',
    border: 'none',
    backgroundColor: '#f7f8fa'
  },
  helperText: {
    marginLeft: '14px'
  }
})

type Props = {
  body: string
  setBody: Dispatch<SetStateAction<string>>
  rowsMin: number
  placeholder?: string
  helperText?: string
  validate?: Validate
}

export const TextareaAutosizeItem = React.memo(
  ({ body, setBody, rowsMin, placeholder, helperText, validate }: Props) => {
    const classes = useStyles()

    return (
      <Grid alignItems="center" item xs={12}>
        <TextareaAutosize
          id="body"
          autoComplete="body"
          className={classes.body}
          defaultValue={body}
          value={body}
          onChange={(event) => setBody(event.target.value)}
          rowsMin={rowsMin}
          placeholder={placeholder}
        />
        <FormHelperText
          className={classes.helperText}
          error={validate && validate.isError}
        >
          {validate && validate.message ? validate.message : helperText}
        </FormHelperText>
      </Grid>
    )
  }
)
