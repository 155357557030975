import React, { ReactNode } from 'react'
import Grid from '@material-ui/core/Grid'
import { ColumnTitle } from 'views/components/atoms/ColumnTitle'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  row: {
    marginBottom: 8,
    '&:nth-child(n+2)': {
      marginTop: 8
    }
  }
})

type Props = {
  title: string
  children: ReactNode
}

export function Column({ title, children }: Props) {
  const classes = useStyles()
  return (
    <Grid item xs={12} container spacing={2} className={classes.row}>
      <ColumnTitle title={title} />
      <Grid item xs={12} sm={6} container>
        {children}
      </Grid>
    </Grid>
  )
}
