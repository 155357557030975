import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { Encrypted, KeyStringValue, Validate } from 'common/Type'
import {
  AdminAuthSigninMutation,
  AdminAuthSigninMutationVariables,
  AdminAdvertiserAuthProxySigninMutation,
  AdminAdvertiserAuthProxySigninMutationVariables
} from 'API'
import {
  adminAdvertiserAuthProxySignin,
  adminAuthSignin,
  adminTokenRefresh
} from 'graphql/mutations'
import { LoadingContext } from 'contexts/LoadingContext'
import { CallGraphQL } from 'utils/api.utils'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { Amplify } from 'aws-amplify'
import { url } from 'inspector'
import { getIvAndEncryptedString, getDecryptedString } from 'utils/crypto.utils'

const AWS = require('aws-sdk')
AWS.config.region = process.env.REACT_APP_AWS_REGION
const roleArn = process.env.REACT_APP_MANAGEMENT_AWS_COGNITO_ROLE_ARN
const customHeaderName =
  process.env.REACT_APP_MANAGEMENT_HEADER_WEB_IDENTITY_TOKEN
const env = process.env.REACT_APP_ENV

const ENCRYPTION_KEY: string = process.env.REACT_APP_ENCRYPTION_KEY!

type UseParamTypes = {
  id?: string
}

export function CompanyManagementProxySignin() {
  const { id } = useParams<UseParamTypes>()
  const { loadingDispatch } = useContext(LoadingContext)

  useEffect(() => {
    proxyLogin()
  }, [])

  async function proxyLogin() {
    if (!id) {
      alert('代理ログインに失敗しました')
      window.close()
      return
    }

    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: true
    })

    const params: AdminAdvertiserAuthProxySigninMutationVariables = {
      companyId: id!
    }
    const data = await callAdminAdvertiserAuthProxySignin(params)
    if (!data.data?.adminAdvertiserAuthProxySignin) {
      alert('代理ログインに失敗しました')
      window.close()
      return
    }

    const encrypted = getIvAndEncryptedString(
      ENCRYPTION_KEY,
      JSON.stringify(data.data.adminAdvertiserAuthProxySignin)
    )
    const subDomain = data.data.adminAdvertiserAuthProxySignin.sub_domain
    let origin = 'http://localhost:3001'
    if (window.location.host.indexOf('localhost') === -1) {
      if (env === 'production') {
        if (subDomain) {
          origin = `https://${subDomain}.mng-web.totono.app`
        } else {
          origin = 'https://mng-web.totono.app'
        }
      } else if (env === 'develop') {
        origin = 'https://dev.mng-web.totono.app'
      }
    }
    window.location.href = `${origin}/authorize/proxy/signin?iv=${encrypted.ivString}&encrypted=${encrypted.encryptedString}`
  }

  async function callAdminAdvertiserAuthProxySignin(
    params: AdminAdvertiserAuthProxySigninMutationVariables
  ): Promise<GraphQLResult<AdminAdvertiserAuthProxySigninMutation>> {
    return await CallGraphQL(adminAdvertiserAuthProxySignin, params, false)
  }

  return <></>
}
