import React, { useEffect, useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Grid, TextField, Divider } from '@material-ui/core'
import {
  CustomerNoticeAndCreatedUserAndUpdatedUserDeletedUser,
  Validate
} from 'common/Type'
import { LoadingContext } from 'contexts/LoadingContext'
import { Layout } from 'views/components/template/Layout'
import { LeftAndRightWrapper } from 'views/components/organisms/LeftAndRightWrapper'
import { Left } from 'views/components/molecules/Left'
import { Right } from 'views/components/molecules/Right'
import { FormLayout } from 'views/components/organisms/FormLayout'
import { Column } from 'views/components/molecules/Column'
import { DateLabel } from 'views/components/atoms/DateLabel'
import { Name } from 'views/components/atoms/Name'
import { TextareaAutosizeItem } from 'views/components/atoms/TextareaAutosizeItem'
import { ButtonFooter } from 'views/components/organisms/ButtonFooter'
import { Loading } from 'views/components/atoms/Loaging'
import {
  CreateCustomerNoticeMutation,
  CreateCustomerNoticeMutationVariables,
  GetCustomerNoticeQuery,
  GetCustomerNoticeQueryVariables,
  TableCustomerNoticeFilterInput,
  UpdateCustomerNoticeMutation,
  UpdateCustomerNoticeMutationVariables
} from 'API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { getCustomerNotice } from 'graphql/queries'
import { CallGraphQL } from 'utils/api.utils'
import { createCustomerNotice, updateCustomerNotice } from 'graphql/mutations'

type UseParamTypes = {
  id?: string
}

type CustomerNoticeValidate = {
  body?: Validate
}

export function CustomerNoticeEdit() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { loadingDispatch } = useContext(LoadingContext)
  const { id } = useParams<UseParamTypes>()
  const [validate, setValidate] = useState<CustomerNoticeValidate>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [body, setBody] = useState('')
  const [url, setUrl] = useState('')
  const [
    customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser,
    setCustomerNoticeAndCreatedUserAndUpdatedUserDeletedUser
  ] = useState<CustomerNoticeAndCreatedUserAndUpdatedUserDeletedUser>()
  const title = id
    ? '入居者向けお知らせ管理'
    : '新規作成 | 入居者向けお知らせ管理'

  function handleCancel() {
    if (
      customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser &&
      customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.customerNotice
    ) {
      setBody(
        customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.customerNotice
          .body
          ? customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
              .customerNotice.body
          : ''
      )
      setUrl(
        customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.customerNotice
          .url
          ? customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
              .customerNotice.url
          : ''
      )
    } else {
      setBody('')
      setUrl('')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    await Promise.all([id ? fetchCustomerNotice() : null])
    setIsLoading(false)
  }

  async function fetchCustomerNotice() {
    const tableCustomerNoticeFilterInput: TableCustomerNoticeFilterInput = {
      id: { eq: Number(id) }
    }
    const params: GetCustomerNoticeQueryVariables = {
      filter: tableCustomerNoticeFilterInput
    }
    const getCustomerNoticeResult = await callGetCustomerNotice(params)
    if (
      getCustomerNoticeResult.data &&
      getCustomerNoticeResult.data.getCustomerNotice
    ) {
      const getCustomerNoticeResultData =
        getCustomerNoticeResult.data.getCustomerNotice
      setCustomerNoticeAndCreatedUserAndUpdatedUserDeletedUser(
        getCustomerNoticeResultData as CustomerNoticeAndCreatedUserAndUpdatedUserDeletedUser
      )
      if (getCustomerNoticeResultData.customerNotice) {
        setBody(
          getCustomerNoticeResultData.customerNotice.body
            ? getCustomerNoticeResultData.customerNotice.body
            : ''
        )
        setUrl(
          getCustomerNoticeResultData.customerNotice.url
            ? getCustomerNoticeResultData.customerNotice.url
            : ''
        )
      }
    } else {
      enqueueSnackbar(' データ取得に失敗しました', {
        variant: 'error'
      })
    }
  }

  async function callGetCustomerNotice(
    params: GetCustomerNoticeQueryVariables
  ): Promise<GraphQLResult<GetCustomerNoticeQuery>> {
    return await CallGraphQL(getCustomerNotice, params, false)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (
      !window.confirm(
        `全ユーザに通知されます。内容に誤りがなければOKボタンを押下してください。`
      )
    ) {
      return
    }

    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: true
    })

    if (validateForm()) {
      loadingDispatch({
        type: 'SET', // reducerで指定したtypeを使う
        isLoading: false
      })
      enqueueSnackbar('入力内容に誤りがあります', {
        variant: 'warning'
      })
      return
    }

    if (id) {
      const params: UpdateCustomerNoticeMutationVariables = {
        input: {
          id: id,
          status: 1,
          body: body,
          url: url
        }
      }
      const response = await callUpdateCustomerNotice(params)
      if (response.data && response.data.updateCustomerNotice) {
        enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        await fetchData()
      } else {
        enqueueSnackbar('保存に失敗しました', {
          variant: 'error'
        })
      }
    } else {
      const params: CreateCustomerNoticeMutationVariables = {
        input: {
          status: 1,
          body: body,
          url: url
        }
      }
      const response = await callCreateCustomerNotice(params)
      if (response.data && response.data.createCustomerNotice) {
        enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        history.push(
          `/notice/customer/edit/${response.data.createCustomerNotice.id}`
        )
      } else {
        enqueueSnackbar('保存に失敗しました', {
          variant: 'error'
        })
      }
    }
    loadingDispatch({
      type: 'SET', // reducerで指定したtypeを使う
      isLoading: false
    })
  }

  function validateForm() {
    const valid: CustomerNoticeValidate = {}
    if (!body || body === '') {
      valid.body = { isError: true, message: '本文は必須です' }
    }
    setValidate(valid)
    return Object.keys(valid).length > 0
  }

  async function callUpdateCustomerNotice(
    params: UpdateCustomerNoticeMutationVariables
  ): Promise<GraphQLResult<UpdateCustomerNoticeMutation>> {
    return await CallGraphQL(updateCustomerNotice, params, false)
  }

  async function callCreateCustomerNotice(
    params: CreateCustomerNoticeMutationVariables
  ): Promise<GraphQLResult<CreateCustomerNoticeMutation>> {
    return await CallGraphQL(createCustomerNotice, params, false)
  }

  return (
    <form noValidate onSubmit={(e) => handleSubmit(e)}>
      <Layout title={title} backEnabled={true}>
        <LeftAndRightWrapper>
          <Left
            footer={
              id ? undefined : (
                <ButtonFooter
                  text={'※登録すると即時反映されます'}
                  handleCancel={handleCancel}
                />
              )
            }
          >
            <Grid container xs={12}>
              {isLoading ? (
                <Loading isLoading={isLoading} />
              ) : (
                <FormLayout title={'お知らせ内容'}>
                  <Grid container xs={12} spacing={2}>
                    <TextareaAutosizeItem
                      body={body}
                      setBody={setBody}
                      rowsMin={6}
                      placeholder={'本文*'}
                      validate={validate && validate.body}
                    />
                    <Grid alignItems="center" item xs={12} spacing={1}>
                      <TextField
                        label={'関連URL'}
                        id="url"
                        fullWidth
                        autoComplete="url"
                        variant="filled"
                        value={url}
                        defaultValue={url}
                        onChange={(event) => setUrl(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </FormLayout>
              )}
            </Grid>
          </Left>
          <Right>
            {isLoading ? (
              <Loading isLoading={isLoading} marginTop={20} />
            ) : (
              <>
                {customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser && (
                  <>
                    <FormLayout title={'登録情報'}>
                      {customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.customerNotice &&
                        customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
                          .customerNotice.created_at && (
                          <Column title={'登録日時'}>
                            <DateLabel
                              timestamp={
                                customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
                                  .customerNotice.created_at
                              }
                            />
                          </Column>
                        )}
                      <Divider />
                      {customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser.createdUser &&
                        customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
                          .createdUser.name && (
                          <Column title={'登録者'}>
                            <Name
                              name={
                                customerNoticeAndCreatedUserAndUpdatedUserAndDeletedUser
                                  .createdUser.name
                              }
                            />
                          </Column>
                        )}
                    </FormLayout>
                    <Divider />
                  </>
                )}
              </>
            )}
          </Right>
        </LeftAndRightWrapper>
      </Layout>
    </form>
  )
}
