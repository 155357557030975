import * as crypto from 'crypto'
import { Encrypted } from 'common/Type'

const ENCRYPT_METHOD = 'aes-256-cbc'
const ENCODING = 'hex'

export function getIvAndEncryptedString(
  encryptionKey: string,
  raw: string
): Encrypted {
  const iv = crypto.randomBytes(16)
  const cipher = crypto.createCipheriv(
    ENCRYPT_METHOD,
    Buffer.from(encryptionKey),
    iv
  )
  const encrypted = Buffer.concat([cipher.update(raw), cipher.final()])

  return {
    ivString: iv.toString(ENCODING),
    encryptedString: encrypted.toString(ENCODING)
  }
}

export function getDecryptedString(
  encrypted: Encrypted,
  encryptionKey: string
): string {
  const iv = Buffer.from(encrypted.ivString, ENCODING)
  const encryptedText = Buffer.from(encrypted.encryptedString, ENCODING)
  const decipher = crypto.createDecipheriv(
    ENCRYPT_METHOD,
    Buffer.from(encryptionKey),
    iv
  )
  const decrypted = Buffer.concat([
    decipher.update(encryptedText),
    decipher.final()
  ])

  return decrypted.toString()
}
