import React from 'react'
import { Circle } from 'views/components/atoms/Circle'

type Props = {
  paginationNumber: number
  pageNum: number
  handlePageNumber?: (event: React.MouseEvent<HTMLInputElement>) => void
}

export const CircleWithNumber = React.memo(
  ({ paginationNumber, pageNum, handlePageNumber }: Props) => {
    return paginationNumber === pageNum ? (
      <Circle active key={paginationNumber}>
        {paginationNumber}
      </Circle>
    ) : (
      <Circle key={paginationNumber} onClick={handlePageNumber}>
        {paginationNumber}
      </Circle>
    )
  }
)
