import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

type Props = {
  title: string
}

export function ColumnTitle({ title }: Props) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography variant="body1">{title && title}</Typography>
    </Grid>
  )
}
