import React, { Dispatch, SetStateAction } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

type Props = {
  label: string
  format: string
  value: string | null
  setValue: Dispatch<SetStateAction<string | null>>
  isError?: boolean
  helperText?: string
}

export const CustomDatePicker = React.memo(
  ({ label, format, value, setValue, isError, helperText }: Props) => {
    const handleChangeDate = (
      date: MaterialUiPickersDate | null,
      value?: string | null
    ) => {
      setValue(value ? value : null)
    }
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
        <KeyboardDatePicker
          disableToolbar
          inputVariant="filled"
          id="date"
          name="date"
          margin="normal"
          format={format}
          label={label}
          value={value}
          onChange={handleChangeDate}
          error={isError}
          helperText={helperText}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </MuiPickersUtilsProvider>
    )
  }
)
