import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  right: {
    width: '300px'
  },
  rightBody: {
    flex: 1,
    height: '100%',
    backgroundColor: '#fff'
  }
})

type Props = {
  children: ReactNode
}

export function Right({ children }: Props) {
  const classes = useStyles()

  return (
    <div className={classes.right}>
      <div className={classes.rightBody}>{children}</div>
    </div>
  )
}
