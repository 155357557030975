import React, { ChangeEvent, Dispatch, MouseEvent, SetStateAction } from 'react'
import { SvgIconProps } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { File } from 'views/components/atoms/File'
import { FileAddButton } from 'views/components/atoms/FileAddButton'
import { FileObject } from '../../../common/Type'

type Props = {
  text: string
  accept: string
  files: FileObject[]
  setFiles: Dispatch<SetStateAction<FileObject[]>>
  maxFileLength: number
  onClick?: (event: ChangeEvent<HTMLInputElement>) => void
  startIcon?: React.HTMLProps<SvgIconProps>
  endIcon?: React.HTMLProps<SvgIconProps>
  hasError?: boolean
  helperText?: string
  handleDelete?: (event: MouseEvent<HTMLButtonElement>, index: number) => void
}
export const FileForm = React.memo(
  ({
    text,
    accept,
    files,
    setFiles,
    maxFileLength,
    onClick,
    startIcon,
    endIcon,
    hasError,
    helperText,
    handleDelete
  }: Props) => {
    return (
      <>
        {files.length > 0 &&
          files.map((file, index) => (
            <File
              fileTitle={file.filename}
              handleDelete={(event) =>
                handleDelete && handleDelete(event, index)
              }
            />
          ))}
        {files && files.length < maxFileLength && (
          <FileAddButton
            text={text}
            accept={accept}
            files={files}
            setFiles={setFiles}
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
            hasError={hasError}
            helperText={helperText}
          />
        )}
      </>
    )
  }
)
