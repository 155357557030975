import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import {
  PaletteColor,
  PaletteColorOptions
} from '@material-ui/core/styles/createPalette'
import awsmobile from 'aws-exports'
import { Amplify } from 'aws-amplify'

declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {
    primaryColor?: PaletteColorOptions
    menuIcon?: PaletteColorOptions
    fontColor?: PaletteColorOptions
    subFontColor?: PaletteColorOptions
    white?: PaletteColorOptions
    black?: PaletteColorOptions
    gray?: PaletteColorOptions
    darkGray?: PaletteColorOptions
    red?: PaletteColorOptions
    blue?: PaletteColorOptions
    lightBlue?: PaletteColorOptions
    lightBlue2?: PaletteColorOptions
    primaryButtonColor?: PaletteColorOptions
    primaryButtonBgColor?: PaletteColorOptions
    primaryButtonHoverColor?: PaletteColorOptions
    secondaryButtonColor?: PaletteColorOptions
    secondaryButtonBgColor?: PaletteColorOptions
    secondaryButtonHoverColor?: PaletteColorOptions
  }

  interface Palette {
    primaryColor: PaletteColor
    menuIcon: PaletteColor
    fontColor: PaletteColor
    subFontColor: PaletteColor
    white: PaletteColor
    black: PaletteColor
    gray: PaletteColor
    darkGray: PaletteColor
    red: PaletteColor
    blue: PaletteColor
    lightBlue: PaletteColor
    lightBlue2: PaletteColor
    primaryButtonColor: PaletteColor
    primaryButtonBgColor: PaletteColor
    primaryButtonHoverColor: PaletteColor
    secondaryButtonColor: PaletteColor
    secondaryButtonBgColor: PaletteColor
    secondaryButtonHoverColor: PaletteColor
  }
}

const theme = createMuiTheme({
  props: {
    MuiFilledInput: {
      disableUnderline: true
    }
  },

  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#f7f8fa',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
      }
    },
    MuiDivider: {
      root: {
        marginTop: 1
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '700px'
      }
    },
    MuiTableCell: {
      root: {
        height: '64px',
        fontFamily: 'Hiragino Sans',
        fontWeight: 300,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#2c3f58'
      },
      stickyHeader: {
        fontFamily: 'Hiragino Sans',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#72809d',
        backgroundColor: '#fafbfd'
      }
    }
  },
  palette: {
    primaryColor: { main: '#09AAC2' },
    menuIcon: { main: '#232020' },
    fontColor: { main: '#2C3F58' },
    subFontColor: { main: '#72809D' },
    white: { main: '#ffffff' },
    black: { main: '#000000' },
    gray: { main: '#f5f5f5' },
    darkGray: { main: '#778698' },
    red: { main: '#FF3E1D' },
    blue: { main: '#0f8cf0' },
    lightBlue: { main: '#696cff' },
    lightBlue2: { main: '#a7a9fb' },
    primaryButtonColor: { main: '#fff' },
    primaryButtonBgColor: { main: '#0f8cf0' },
    primaryButtonHoverColor: { main: '#0b62a8' },
    secondaryButtonColor: { main: '#000000' },
    secondaryButtonBgColor: { main: '#F6F8FA' },
    secondaryButtonHoverColor: { main: '#c5d2df' }
  },

  typography: {
    fontFamily: 'Hiragino Sans W6, Hiragino Sans W3'
  }
})

Amplify.configure({
  ...awsmobile,
  Analytics: { disabled: true }
})

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
