import React, { ReactNode, useState } from 'react'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles } from '@material-ui/core/styles'
import { OrderBy, TableHeadColumn } from 'common/Type'
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import { SortDirection } from '@material-ui/core/TableCell/TableCell'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  }
}))

type Props = {
  chips: ReactNode
}

export const SearchConditionChipWrapper = React.memo(({ chips }: Props) => {
  const classes = useStyles()

  return (
    <Grid container xs={12} className={classes.container}>
      <Grid item xs={12}>
        {chips && (
          <Paper component="ul" className={classes.chips}>
            {chips}
          </Paper>
        )}
      </Grid>
    </Grid>
  )
})
