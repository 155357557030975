import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  content: {
    flex: 1,
    display: 'flex',
    height: '100%'
  }
})

type Props = {
  children: ReactNode
}

export function LeftAndRightWrapper({ children }: Props) {
  const classes = useStyles()

  return <div className={classes.content}>{children}</div>
}
