import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import { Loading } from 'views/components/atoms/Loaging'

const useStyles = makeStyles({
  listBodyWrapper: {
    width: '100%',
    marginTop: 20
  },
  listBody: {
    maxHeight: '75vh',
    overflow: 'auto'
  }
})

type Props = {
  isLoading: boolean
  children: ReactNode
}

export function ListBody({ isLoading, children }: Props) {
  const classes = useStyles()
  return (
    <>
      {isLoading ? (
        <Loading isLoading={isLoading} marginTop={20} />
      ) : (
        <Paper className={classes.listBodyWrapper}>
          <div className={classes.listBody}>{children}</div>
        </Paper>
      )}
    </>
  )
}
