import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { formatDate } from 'utils/date.utils'

const useStyles = makeStyles({
  ago: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  hmi: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  }
})

type Props = {
  timestamp: string
}

export function DateLabel({ timestamp }: Props) {
  const classes = useStyles()

  const ago = formatDate('ago', timestamp)
  const hmi = formatDate('hmi', timestamp)

  return (
    <Grid container direction="column">
      <Grid item className={classes.ago}>
        <span>{ago}</span>
      </Grid>
      <Grid item className={classes.hmi}>
        <span>{hmi}</span>
      </Grid>
    </Grid>
  )
}
