import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { SvgIconProps } from '@material-ui/core'

const useStyles = makeStyles<Theme, Props>((theme) => {
  return {
    button: {
      color: (props) =>
        props.color === 'primary'
          ? theme.palette.primaryButtonColor.main
          : theme.palette.primaryButtonColor.main,
      backgroundColor: (props) =>
        props.color === 'primary'
          ? theme.palette.primaryButtonBgColor.main
          : theme.palette.primaryButtonBgColor.main,
      '&:hover': {
        backgroundColor: (props) =>
          props.color === 'primary'
            ? theme.palette.primaryButtonHoverColor.main
            : theme.palette.primaryButtonHoverColor.main
      }
    }
  }
})

type Props = {
  color: 'primary'
  to: string
  name: string
  startIcon?: React.HTMLProps<SvgIconProps>
  endIcon?: React.HTMLProps<SvgIconProps>
}

// TODO 試す export const LinkButton = React.memo((props: Props) => {
export function LinkButton(props: Props) {
  const classes = useStyles(props)
  const { to, name, startIcon, endIcon } = props

  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={to}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {name}
    </Button>
  )
}
