import React, { ReactNode, useContext, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { TableHeaderWithSort } from 'views/components/molecules/TableHeaderWithSort'
import { OrderBy, TableHeadColumn } from 'common/Type'
import { SearchConditionContext } from 'contexts/SearchConditionContext'

type Props = {
  tableHeadColumnList: TableHeadColumn[]
  children: ReactNode
  handlePageNumber: (pageNum: number) => void
}

export function TableList({
  tableHeadColumnList,
  handlePageNumber,
  children
}: Props) {
  const { searchConditionState, searchConditionDispatch } = useContext(
    SearchConditionContext
  )
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>(
    searchConditionState &&
      searchConditionState.searchCondition &&
      searchConditionState.searchCondition &&
      searchConditionState.searchCondition.orderBy
      ? (searchConditionState.searchCondition.orderBy as OrderBy)
      : undefined
  )

  function setSearchCondition(orderBy: OrderBy) {
    searchConditionDispatch({
      type: 'SET',
      searchCondition: { orderBy: orderBy }
    })
  }

  const handleChangeSort = (orderByParam: OrderBy) => {
    console.log('orderByParam', orderByParam)
    setOrderBy(orderByParam)
    setSearchCondition(orderByParam)
    handlePageNumber(1)
  }

  return (
    <>
      <Table stickyHeader aria-label="sticky table">
        <TableHeaderWithSort
          orderByParam={orderBy}
          tableHeadColumnList={tableHeadColumnList}
          handleChangeSort={handleChangeSort}
        />
        <TableBody>{children}</TableBody>
      </Table>
    </>
  )
}
