import React from 'react'
import { AdminUser } from '../common/Type'

export type State = {
  adminUser?: AdminUser
}

export type Action = {
  type: 'SET'
  adminUser?: AdminUser
}

export function adminUserReducer(state: State, action: Action) {
  if (action.type === 'SET') {
    return { ...state, adminUser: action.adminUser }
  }
  throw new Error()
}
