import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { Loading } from 'views/components/atoms/Loaging'
import { LoadingContext } from 'contexts/LoadingContext'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

export const BackDropLoading = React.memo(() => {
  const classes = useStyles()
  const { loadingState } = useContext(LoadingContext)

  return (
    <>
      {loadingState && loadingState.isLoading && (
        <Backdrop
          className={classes.backdrop}
          open={loadingState && loadingState.isLoading}
        >
          <Loading isLoading={loadingState && loadingState.isLoading} />
        </Backdrop>
      )}
    </>
  )
})
