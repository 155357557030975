import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Grid, SvgIconProps } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primaryButtonColor.main,
    backgroundColor: theme.palette.primaryButtonBgColor.main,
    '&:hover': {
      backgroundColor: theme.palette.primaryButtonHoverColor.main
    },
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

type Props = {
  text: string
  type: 'submit' | 'button'
  handleClick?: React.MouseEventHandler<HTMLButtonElement>
  startIcon?: React.HTMLProps<SvgIconProps>
  endIcon?: React.HTMLProps<SvgIconProps>
}

export const PrimaryButton = React.memo(
  ({ text, type, handleClick, startIcon, endIcon }: Props) => {
    const classes = useStyles()

    return (
      <Button
        variant="contained"
        className={classes.button}
        type={type}
        onClick={handleClick}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {text}
      </Button>
    )
  }
)
